.csPixieEditorContainer {
    position: fixed;
    height: '100vh';
    width: '100vh';
    inset: 0;
    z-index: 29;
    background-color: rgba(0,0,0, 0.2);
    display: none !important;
    overflow-y: auto;
    &.visible {
        display: inherit !important;
    }
}

.csPixieEditor {
    position: absolute;
    margin: "auto";
    z-index: 30;
    inset: 0;
    top: 50;
    box-shadow: 0 0 10px -4px;
    width: 1200px;
    height: 845px;
    margin: 60px auto;
    border-radius: 10px;
}

.csPixieDialogCloseButton {
    padding: 10px;
    position: absolute;
    border-radius: 20px;
    top: 0px;
    right: 0px;
    z-index: 10;
    cursor: pointer;
    margin-top: -33px;
    margin-right: -36px;
    background: #f9f9f9;
    border: 2px solid #007acc;
    &:hover {
        background-color: #007acc;
        color: #f9f9f9;
    }
}