.csFullCalendar {
    display: flex;

    .csFullCalendarActionBtns {
        display: flex;
        margin-left: auto;

        .csFullCalendarSelectedMonth {
            color: #3A3A3A;
            font-size: 24px;
            font-weight: 500;
            margin-top: 15px;
        }

        .csNextPrev {
            display: flex;
            margin: 20px 15px 0 15px;
            
            svg {
                cursor: pointer;
                width: 25px;
                height: 25px;
            }
        }
    }
}
.fc-popover,.fc-more-popver {
    visibility: hidden;
}