.listFilter {
    display: none;
    @media (min-width: 992px) {
        display: block;
    }
    .filter {
        padding-right: 15px;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }
}

.list-details-container {
    display: grid;
    grid-template-columns: 200px 1fr;

    .list-details, .list-details-values {
        div {
            height: 60px;
            display: flex;
            align-items: center;
            gap: 15px;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 16px;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }
        }
    }



    .list-details  {
        div {
            display: flex;

            span {
                color: var(--accent-color);
                display: inline-block;
                border: 1px solid var(--border-color);
                background-color: var(--background-color);
                padding: 5px;
                border-radius: 5px;
            }
        }
    }

    .list-details-values {
        div {
            font-weight: 400;
        }
    }
}

#folderButtons {
    padding: 8px !important;
    @media (min-width: 992px) {
        padding: 12px 48px !important;
    }
}

#list-buttons {
    display: flex;
    justify-content: space-between;
    @media (min-width: 992px) {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
    }
}

#new-list-button {
    max-height: 40px;
    @media (min-width: 992px) {
        max-height: unset;
    }
}

#filter-button-list {
    padding-left: 15px !important;
    margin-bottom: 10px;
    @media (min-width: 992px) {
        display: none;
    }
}