@import "../../components/global/color.scss";

.hide-in-mobile {
    display: none;
    @media (min-width: 992px) {
        display: block;
    }
}

.sidebar-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: $dark-400;

    a.active {
        .sidebar-item {
            background: #F2F2F2;
        }
    }

    .sidebar-item {
        display: flex;
        gap: 10px;
        padding: 12px;


        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);

        &.active {
            background-color: red;
        }

        .title {
            color: currentColor;
            font-weight: 500;
        }
    }
}

.divider {
    width: 1px;
    background-color: $light-600;
    min-height: calc(100vh - 8rem);
    height: 100%;
    margin: auto;
}

.padding-class {
    padding: 0;
    @media (min-width: 992px) {
        padding: 0 15px;
    }
}

.padding-left {
    padding-left: 0;
    @media (min-width: 992px) {
        padding-left: 15px;
    }
}

.addTopMargin {
    margin-top: 1rem;
    @media (min-width: 992px) {
        margin-top: 0;
    }
}

.input-width {
    width: 100%;
    @media (min-width: 992px) {
        width: 20rem;
    }
}

.account {
    position: relative;
    margin-top: 2rem;

    &::before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: $light-600;
        position: absolute;
        top: -2rem;
        display: none;
        @media (min-width: 992px) {
            display: block;
        }
    }

    &-profile {
        color: $dark-400;

        h1.heading {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        .card {
            padding: 15px;
            border-radius: 5px;
            border: 1px solid #D7D7D7;
            background: #F9F9F9;
            box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
            @media (min-width: 992px) {
                padding: 30px;
            }
        }
    }

    &-workspace {
        color: $dark-400;
        
        h1.heading {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        #workspace-header {
            flex-direction: column;
            gap: 0.5rem;
            @media (min-width: 992px) {
                flex-direction: row;
                justify-content: space-between;
            }

            #create-workspace-button {
                max-width: max-content;
            }
        }
    }

    &-whitelabel {
        color: $dark-400;
        
        h1.heading {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        .card {
            padding: 30px;
            border-radius: 5px;
            border: 1px solid #D7D7D7;
            background: #F9F9F9;
            box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
            max-width: 600px;

            h2 {
                font-weight: 500;
            }

            .custom-profile-image-dashboard {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border: 1px solid #D7D7D7;
                overflow: hidden;
                padding: 1rem;
                border-radius: 5px;
        
                &:hover {
                    .custom-profile-image-dashboard-image-edit {
                        display: grid;
                    }
                }
                
                .custom-profile-image-dashboard-image {
                    width: 100%;
                    height: 100%;
                    border: none;
                }
                .custom-profile-image-dashboard-image-edit {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    margin: auto;
                    display: none;
                    place-items: center;
                    background-color: rgba(0, 0, 0, 0.5);
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }

    &-password {
        color: $dark-400;

        h1.heading {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        .card {
            padding: 30px;
            border-radius: 5px;
            border: 1px solid #D7D7D7;
            background: #F9F9F9;
            box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
        }
    }

    &-2fa {
        color: $dark-400;

        h1.heading {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        .card {
            padding: 30px;
            border-radius: 5px;
            border: 1px solid #D7D7D7;
            background: #F9F9F9;
            box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
        }
    }

    &-users {
        color: $dark-400;

        .header {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 1rem;
            max-height: 55px;
            @media (min-width: 992px) {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
            
            .group {
                display: flex;
                align-items: center;
                gap: 1rem;
                max-height: 45px;
                padding: 3px;
                margin-top: 0.5rem;
                flex-direction: column;
                gap: 0;
                @media (min-width: 992px) {
                    margin-top: 0;
                    overflow: hidden;
                    flex-direction: row;
                    gap: 1rem;
                }

                .search-wrapper {
                    display: flex;
                    align-items: start;
                    padding: 9px;
                    border-radius: 5px;
                    border: 1px solid #D7D7D7;
                    
                    .search {
                        width: 100%;
                        @media (min-width: 992px) {
                            width: unset;
                            padding-left: 5px;
                        }
                    }
                }

                #add-user-plan-usage {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    margin-left: -24px;
                    @media (min-width: 992px) {
                        margin-left: 0;
                    }
                }

                #gradient-thingy-wrapper {
                    $size: 92px;
                    min-width: $size;
                    min-height: $size;
                    border-radius: 50%;
                    position: relative;
                    top: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    outline: 1px solid $light-600;
                    outline-offset: -1px;
                    isolation: isolate;

                    &::before{
                        $difference: 15px;
                        content: "";
                        position: absolute;
                        z-index: 2;
                        height: calc($size - $difference);
                        width: calc($size - $difference);
                        background-color: $light-100;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        border-radius: 50%;
                        outline: 1px solid $light-600;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        height: 2px;
                        width: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        outline: 1px solid $light-600;
                    }

                    .plan-usage {
                        z-index: 100;
                        top: -1rem;
                        position: relative;
                        font-weight: 500;
                        font-size: 24px;

                        span.infinity {
                            font-weight: 900;
                        }
                    }

                }
            }
        }

        #user-table-data {
            margin-top: 108px;
            padding-block: 16px;
            background-color: #F9F9F9;
            position: relative;
            @media (min-width: 450px) {
                margin-top: 100px;
            }
            @media (min-width: 992px) {
                margin-top: 0;
                padding-block: 0;
            }
        }
    }

    &-apikeys {
        .header {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 1rem;
            max-height: 55px;
            gap: 1rem;
            @media (min-width: 992px) {
                gap: 0;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            #header-buttons {
                display: flex;
                gap: 1rem;
            }
        }

        .edit-create {
            .edit-create-form {
                width: clamp(300px, 50%, 500px);
                margin-inline: auto;
            }
        }

        .customButtons {
            padding: 16px !important;
            @media (min-width: 992px) {
                padding: 12px 48px !important;
            }
        }

        #api-keys-data-table {
            margin-top: 60px;
            @media (min-width: 992px) {
                margin-top: 0;
            }
        }
    }

    &-integrations {
        color: $dark-400;

        .integration-search-wrapper {
            display: flex;
            align-items: center;
            border: 1px solid $light-600;
            border-radius: 5px;
            padding: 9px;
            gap: 10px;
            margin: auto;
            width: clamp(300px, 100%, 700px);
            margin-bottom: 3rem;

            input {
                width: 100%;
            }
        }

        .integrations-wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 25px;
            margin: auto;
            width: clamp(300px, 100%, 1920px);

            .integration-item {
                $size: 200px;
                width: $size;
                height: $size;
                padding: 22px 36px 29px 36px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                border-radius: 5px;
                border: 1px solid $light-600;
                background: $light-100;
                box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
                cursor: pointer;
                margin: auto;
                position: relative;

                .zapier-image {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    width: 25px;
                    height: 25px;
                }

                .image {
                    $size: 100px;
                    aspect-ratio: 1 / 1;
                    height: $size;
                    width: $size;
                }

                .name {
                    font-weight: 500;
                    text-align: center;

                }
            }
        }

        #bottom-of-page {
            height: 100px;
        }

        .individual-integration {
            .card {
                $size: 300px;
                height: $size;
                width: $size;
                border: 1px solid $light-600;
                background: $light-100;
                box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
                border-radius: 5px;
                padding: 35px 60px 35px 60px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                margin: 40px auto;

                h1 {
                    font-size: 24px;
                    font-weight: 500;
                    text-transform: capitalize;
                }

                img {
                    $size: 175px;
                    aspect-ratio: 1 / 1;
                    height: $size;
                    width: $size;
                }

            }

            .back-button {
                $size: 45px;
                height: $size;
                width: $size;
                color: $dark-200;
                border-radius: 5px;
                border: 3px solid currentColor;
                display: grid;
                place-items: center;
                
            }

            .mapping {
                max-width: 900px;
                margin: auto;

                .button-wrapper {
                    display: flex;
                    gap: 40px;
                    justify-content: center;
                    margin-top: 20px;
                }
            }
        }
    }
}