@import "../global/color.scss";

.csSteps {
    position: relative;
    display: flex;
    background: rgb(242, 242, 242);
    height: 8px;
    border-radius: 10px;
    margin-block: 70px 90px;
    margin-inline: auto;
    justify-content: space-between;
    width: clamp(300px, 300px, 300px);
}

.csStep {
    position: initial;
    z-index: 1;
    cursor: default;
    font-size: 12px;

    .csStepDot {
        $size: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: clamp(45px, 45px, 45px);
        width: clamp(45px, 45px, 45px);
        padding: 8px;
        border-radius: 5px;
        color: var(--accent-color, $dark-200) ;
        background-color: var(--accent-background, $light-200);
        outline: 3px solid var(--accent-outline, $light-600) ;
        margin: -20px auto 10px auto;
    }

    font-weight: bold;
}

.csStepsProgress {
    max-width: 550px;
    height: 8px;
    background: #3AC569;
    position: absolute;
    border-radius: 10px;
    left: 2rem;
}

@media (min-width: 992px) {
    .csSteps {
        width: clamp(600px, 600px, 600px);
    }
  
    .csStep {
        font-size: 12px;
    }
}