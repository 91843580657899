.knob-progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: sans-serif;
}

.knob {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #eee;
    position: relative;
    margin-bottom: 0.5rem;
}

.knob-progress {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #3498db;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center bottom;
    transform: rotate(-135deg);
    transition: transform 0.3s ease-in-out;
}

.progress-percentage {
    font-size: 1.2rem;
    font-weight: bold;
}

.deliverabilityContainer {
    .csCardBox {
        padding: 30px;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F2F2F2;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
    }

    .blockTitle {
        color: #343434;
        font-size: 24px;
        font-weight: 500;
    }

    .errorTitle {
        color: #343434;
        font-size: 20px;
        font-weight: 500;
    }

    .errorCard {
        margin-top: 10px;
        display: flex;
        padding: 12px;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
    }
}