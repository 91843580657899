@import "../global/color";

.csCustomToggle {
    background: #f9f9f9;
    cursor: pointer;
    border: 1px solid $light-600;
    margin: 10px 0;
    border-radius: 5px;
    width: 100%;
    padding: 12px;
    transition: 0.1s ease-in-out;
    display: flex;
    gap: 0.75rem;
    font-weight: 500;
    color: $dark-400;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.02);
    &.active {
        background-color: $blue-light-700;
        color: #f9f9f9;
    }
}