* {
  scrollbar-width: auto;
  scrollbar-color: #5D5D5D #3A3A3A;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  border-radius: 50px;
  background: #3A3A3A;
}

*::-webkit-scrollbar-thumb {
  background-color: #5D5D5D;
  border: 3px solid #3A3A3A;
  border-radius: 22px;
}