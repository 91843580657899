.reportCalendar {
    margin-top: 20px;
    .calendarCard {
        display: flex;
        border-radius: 5px;
        color: #3a3a3a;
        padding: 3px;

        &.error {
            border: 2px solid #EF898C;
            background: #F5D3D4;
        }

        &.success {
            border: 2px solid #89DCA5;
            background: #D3EFDC;
        }

        &.warning {
            border: 2px solid #FBD355;
            background: #F3E8C4;
        }

        .errorText {
            color: #E53A40;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .successText {
            color: #3AC569;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .warningText {
            color: #F9C00C;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .textSection {
            font-size: 14px;
            margin-left: 5px;
        }

        .eventTitle {
            width: 100%;
            max-width: 150px; /* Adjust the maximum width as per your preference */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            /* Add additional styles as needed */
        }          

        &:hover {
            width: max-content;
            z-index: 99999999;
            .eventTitle {
                max-width: max-content;
                overflow: auto;
                text-overflow: unset;
            }
        }
    }
}

#mobile-device-reports-message {
    display: flex;
    height: 100dvh;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    @media (min-width: 992px) {
        display: none;
    }
}

#large-screen-content {
    display: none;
    @media (min-width: 992px) {
        display: block;
    }
}