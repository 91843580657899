.billing {
    .blCheck {
        border: 1px solid #ccc;
        padding: 10px 20px;
        border-radius: 5px;
        width: max-content;
        cursor: pointer;

        .planHeader {
            display: flex;
            border-bottom: 1px solid #d7d7d7;
            padding-bottom: 5px;
            margin-bottom: 5px;

            .planTitle {
                display: flex;
            }

            .planPrice {
                margin-left: auto;
            }
        }

        .planDesc {
            color: #5D5D5D;
            font-size: 13px;
            font-weight: 400;
        }

        &.active {
            border: 1px solid #03A6FF;
            outline: 1px solid #03A6FF;
            background: #F2F2F2;
            box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
        }
    }

    input {
        width: 300px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        border-radius: 8px;
    }

    .number-input {
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;
        padding: 3px 6px;

        input {
            background-color: #F9F9F9;
            border: none !important;
        }
    }
    
    .number-input__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        outline: none;
    }
    
    .number-input__field {
        // width: 80px;
        padding: 5px;
        text-align: center;
    }


    h2 {
        color: #3A3A3A;
        font-size: 16px;
        font-weight: 500;
    }
    
    p {
        font-size: 18px;
    }

    a {
        color: #007aff;
        font-size: 18px;
        // font-weight: bold;
        // border-bottom: 2px solid #007aff;
    }

    // New Plan //
    .pricing-plan-card {
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        .title {
            font-size: 24px;
            margin: 10px 0;
        }
        
        .price {
            font-size: 20px;
            color: #007bff;
        }
        
        .features {
            list-style: none;
            padding: 0;
            margin: 1rem 1rem 2rem 2rem;
        }
        
        .features li {
            margin-bottom: 5px;
            display: flex;
            gap: 0.5rem;
            text-align: left;
            color: #60697b;

            .check {
                background-color: #def4ee;
                border-radius: 50%;
                color: #45c4a0;
            }

            .highlight {
                background-color: #fef1de;
                padding-inline: 5px;
                border-radius: 5px;
                font-weight: 500;
            }
        }
    }
}

.billingInfo {
    margin-left: 30px;
    width: 450px;
    border-radius: 5px;
    border: 2px solid #AFCFF1;
    background: linear-gradient(135deg, #F9F9F9 0%, rgba(3, 166, 255, 0.00) 100%);
    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 22px 20px;
    position: sticky;
    top: 20px;
    z-index: 1;

    .billingTitle {
        display: flex;
        font-size: 24px;
        font-weight: 600;

        svg {
            margin-top: 3px;
            margin-right: 5px;
        }
    }

    .content {
        margin-top: 15px;
        background-color: #f9f9f9;
        border: 1px solid #d7d7d7;
        border-radius: 5px;
        padding: 20px;

        .productItem {
            display: flex;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 5px;

            .productPrice {
                margin-left: auto;
            }
        }

        .coupon {
            display: flex;
            margin-top: 5px;

            .couponInput {
                width: 190px;
                padding: 8px;
                border-radius: 5px;
                border: 1px solid #D7D7D7;
                background: #F9F9F9;
            }

            .discount {
                color: #3AC569;
                margin-top: 5px;
                margin-left: auto;
                font-size: 16px;
            }

            .couponError {
                color: #E53A40;
                margin-left: 15px;
                font-size: 14px;
            }
        }

        .totalAmount {
            display: flex;
            span {
                margin-top: 20px;
                margin-left: auto;
                color: #3A3A3A;
                font-size: 40px;
                font-weight: 500;
            }
        }

        .subscriptionCycle {
            display: flex;
        }
    }
}

.planUsage {
    width: 100%;
    margin-bottom: 30px;
    tr {
        th {
            text-align: left;
        }
    }
}

.csBtnTrial {
    padding: 5px 10px;
    // border: 2px solid #000;
    border-radius: 5px;
    background: #c1c4d6;
    color: #f9f9f9;
}

.planView {
    color: #3A3A3A;

    .planTitle {
        font-size: 24px;
        font-weight: 500;
    }

    .csCard {
        margin-bottom: 20px;
        padding: 15px;
        height: 121px;

        .details {
            display: flex;

            .cardIcon {
                margin-right: 5px;
                div {
                    padding: 3px;
                    border-radius: 5px;
                    
                    &.warning {
                        border: 1px solid #FBD355;
                        background: #F3E8C4;
                        color: #FBD355;
                    }

                    &.pink {
                        border: 1px solid #F484B2;
                        background: #FBD6E5;
                        color: #F484B2;
                    }

                    &.purple {
                        border: 1px solid #9F52E6;
                        background: #E3CCF8;
                        color: #9F52E6;
                    }

                    &.orange {
                        border: 1px solid #FDA761;
                        background: #FED3B0;
                        color: #FDA761;
                    }

                    &.teal {
                        border: 1px solid #51E3D1;
                        background: #E3F6F4;
                        color: #51E3D1;
                    }

                    &.success {
                        border: 1px solid #89DCA5;
                        background: #D3EFDC;
                        color: #89DCA5;
                    }

                    &.info {
                        border: 1px solid #93CCF3;
                        background: #EAF1F7;
                        color: #93CCF3;
                    }

                    &.primary {
                        border: 1px solid #9EC7F1;
                        background: #E8F0F8;
                        color: #9EC7F1;
                    }
                }    
            }
            .title {
                font-size: 24px;
                font-weight: 500;
            }

            .usageNumber {
                margin-left: auto;
                margin-top: -10px;

                .usageInShort {
                    font-size: 32px;
                    font-weight: 500;
                }

                .usageInDetails {
                    font-size: 14px;
                    text-align: center;
                }
            }
        }

        .link {
            cursor: pointer;
            color: #06F;
            font-size: 12px;
        }
    }
}