@import "../global/color.scss";

.trial {
     margin-right: 1.5rem;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     color: $dark-400;

     &:hover {
          .trial-dropdown {
               display: flex;
          }
     }

     &-face {
          display: flex;
          gap: 5px;
     }

     &-dropdown {
          position: absolute;
          display: flex;
          flex-direction: column;
          gap: 10px;
          display: none;
          translate: 0px 55%;

          background-color: $light-100;
          padding: 1rem 0.75rem 0.5rem 0.75rem;
          border: 1px solid $light-600;
          border-radius: 5px;
          z-index: 1;
          box-shadow: -2px 2px 4px 0px #0000000D;
     }
}

@media (min-width: 992px) {
     .change_log, #create-dropdown {
          display: none;
     }
}

@media (min-width: 1200px) {
     .change_log, #create-dropdown {
          display: block;
     }
}
