.cockpitView {

    .csGridAutoFit {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(max-content, min-content));
        grid-auto-flow: dense;
        grid-auto-rows: min-content;
        grid-gap: 10px;
    
        // .item {
        //     width: 100%;
        // }
    }

    .csCardTitle {
        color: #3A3A3A;
        font-size: 24px;
        font-weight: 500;
        display: flex;

        svg {
            margin-top: 5px;
            margin-right: 10px;
        }
    }

    .statBox {
        width: 210px;
        height: 117px;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
        text-align: center;
        padding-top: 20px;

        h1 {
            color: #343434;
            font-size: 32px;
            font-weight: 500;
        }

        p {
            color: #414141;
            font-size: 15px;
            font-weight: 500;
        }
    }
}

.external-link {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &:hover {
        svg {
            visibility: visible;
        }
    }

    svg {
        visibility: hidden;
        height: 16px;
        width: 16px;
    }
}

.csCockpitRow {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    grid-gap: 16px;
  }
  
  @media (min-width: 992px) { 
    .csCockpitRow {
      grid-template-columns: repeat(2, 1fr); 
    }
}
  
.gridItem {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.gridItem::-webkit-scrollbar {
    display: none;
}

.spinner {
    animation: spin 2s linear infinite;
    @keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
    }
}