@import "../../../components/global/color.scss";

.row-column-responsive-list {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
    align-items: center;
    @media (min-width: 992px) {
        flex-direction: row;
    }
}

#google-sheet-input {
    @media (min-width: 992px) {
        width: 500px;
    }
}

table.csMapTable {
    width: 100%;
    margin: 40px 0;
    cursor: default;
    color: $dark-400;

    border-radius: 5px;
    border: 1px solid #D7D7D7;
    background: #F9F9F9;
    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
    border-collapse: separate !important;

    thead {
        background: $light-100;
        position: relative;
        
        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: calc(100% - 3rem);
            background-color: $light-600;
            left: 50%;
            translate: -50% 0;
        }
    }

    th {
        font-size: 16px;
        font-family: 500;

        &:nth-child(1) {
            text-align: left;
            padding-left: 1rem;
            padding-block: 1rem;

        }
        &:nth-child(2) {
            text-align: left;
            padding-left: 1rem;

        }
        &:nth-child(3) {
            text-align: center;
        }
        &:nth-child(4) {
            text-align: center;
        }
    }


    tbody {

        tr {
            text-align: center;

            &:nth-child(2n) {
                background-color: $light-200;
            }
        }

        td {
            font-size: 16px;
            color: currentColor;

            &:nth-child(1) {
                text-align: left;
                padding-left: 1rem;
            }
            &:nth-child(2) {
                text-align: left;
                padding-left: 1rem;

            }
            &:nth-child(3) {
                text-align: center;

                svg {
                    margin: auto;
                    color: var(--color);
                    border-radius: 5px;
                    padding: 2px;

                    border: 2px solid var(--border-color);
                    background-color: var(--background-color);
                }

            }
            &:nth-child(4) {
                text-align: center;
                padding: 6px;
            }
        }
    }
}

.csUploadIcon {
    transform: 0.2s ease-in-out;
    scale: 1.0;
    &:hover {
        scale: 1.2
    }
}

.import-file-details {
    border-radius: 5px;
    border: 1px solid #D7D7D7;
    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
    background-color: $light-200;
    padding: 100px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
    margin: 4rem auto 2rem;

    h2, p, button {
        width: fit-content;
        margin: auto;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
    }
    
    p {
        font-size: 16px;
        font-weight: 500;
    }
}