@import "../../components/global/color.scss";

#top-filter-container, #filter-right-part {
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
    }

    #filter-right-part {
        margin-top: 8px;
        margin-left: -4px;
        gap: 8px;
        @media (min-width: 992px) {
            margin-top: 0;
            margin-left: 0;
            gap: 4px;
        }
    }
}

.mails {

    #preview-email-section {
        padding: 0 10px; 
        @media (min-width: 992px) {
            border-right: 1px solid #D7D7D7;
            paddingRight: 40px;
        }
    }

    .mailContainer {
        background: #f9f9f9;
        margin-top: 20px;
        overflow-y: auto;
        border: 1px solid #D7D7D7;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
    }

    .mail {
        cursor: pointer;
        padding: 15px 20px;
        display: flex;

        .details {
            width: 100%;
            font-size: 14px;
            .name { 
                font-weight: bold; 
                display: flex;

                svg {
                    margin-top: 5px;
                    margin-left: 5px;
                }
            }
            .dated { font-size: 13px; }
            .bodyPreview { opacity: 0.8; }
        }

        &:nth-child(2n) {
            background-color: #f2f2f2 !important;
        }

        &.active{
            background: #DCEBF9 !important;
            border-radius: 4px 4px 0px 0px;
            transition: all .2s ease-in-out
        }
        &:hover {
            background: #DCEBF9 !important;
            border-radius: 4px 4px 0px 0px;
            transition: all .2s ease-in-out
        }
    }
}

#main-container {
    gap: 8px
}

#sequence-name, #subject-section {
    margin-top: 16px;
    @media (min-width: 992px) {
        margin-top: 0;
    }
}

#back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 12px;
    margin-top: 10px;
    border-radius: 5px;
    @media (min-width: 992px) {
        display: none;
    }
}

#subject-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 992px) {
        flex-direction: row;
    }

    #right-part {
        display: flex;
        flex-direction: column;
        gap: 8px;
        @media (min-width: 992px) {
            gap: 16px;
            flex-direction: row;
        }

        #second-part {
            margin-left: -8px;
            @media (min-width: 992px) {
                margin-left: 0;
            }
        }
    }
}

#single-card {
    padding: 16px;
    @media (min-width: 992px) {
        padding: 25px;
    }
    #from-to-section {
        display: flex;
        flex-direction: column;
        @media (min-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

#compose-and-gpt-button-section {
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
    }
}

.compose-email-reply-forward {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    gap: 4px;
    @media (min-width: 992px) {
        flex-direction: row;
        margin-bottom: 8px;
    }
}

#text-input-field {
    @media (min-width: 992px) {
        width: 700px;
    }
}

#draft-gpt-mail {
    @media (min-width: 992px) {
        margin-left: auto;
    }
}
