.csSearchDropdown {
    position: relative;
    .csSearchInput {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        border: 1px solid rgb(86, 165, 255);
        border-radius: 5px;
        i {
            padding: 10px;
            color: black;
            min-width: 25px;
            text-align: center;
        }
        input {
            width: 100%;
            padding: 10px;
            outline: none;
            &:focus { outline: none; }
        }
    }
    .dropdownContent {
        margin-top: 5px;
        width: 100%;
        border-radius: 5px;        
        position: absolute;
        background-color: #f9f9f9;
        min-width: 200px;
        max-height: 100px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        padding: 12px 16px;
        z-index: 1;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
        div {
            margin-left: 5px;
            color: black;
            padding: 3px 10px;
            text-decoration: none;
            &:hover { background-color: #ddd; }
        }
    }
}