.oauth-redirect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    min-height: 300px;

    .error {
        color: #E2626B;
        font-weight: bold;
    }
}