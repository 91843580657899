
.custom-buttons {
    padding: 8px !important;
    @media (min-width: 992px) {
        padding: 12px 48px !important;
    }
}

#tasklist-buttons {
    display: flex;
    justify-content: space-between;
    @media (min-width: 992px) {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
    }

    #toggle-filter-button {
        display: flex;
        @media (min-width: 992px) {
            display: none;                   
        }
    }
}

#mark-as-done {
    @media (min-width: 992px) {
        padding: 10px 20px;
    }
}

#tasklist-filter {
    display: none;
    @media (min-width: 992px) {
        display: block;                   
    }
}

#mobileTasklistFilter {
    display: block;
    width: 280px;
    @media (min-width: 992px) {
        display: none;                   
    }
}

#tasklist-header {
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;                   
    }
}

.csGenerateButton {
    margin-left: 0;
    margin-top: 8px;
    @media (min-width: 992px) {
        margin-left: auto;                   
        margin-top: 0;
    }
}