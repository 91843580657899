.triggerBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 5px;
    @media (min-width: 992px) {
        flex-direction: row;
        gap: unset;
    }

    select {
        width: 100%;
        padding: 5px 10px;
        margin-right: 10px;
        border-radius: 10px;
    }

    .orButton {
        padding: 10px;
        background: #3b82f6;
        border-radius: 9px;
        font-weight: bold;
        color: #f9f9f9;
        cursor: pointer;
    }

    .closeButton {
        padding: 6px 12px;
        background: #e9e9ed;
        border-radius: 10px;
        transition: 0.2s ease-in-out;
        @media (min-width: 992px) {
            margin-left: 10px;
        }
        &:hover {
            background: #f63b70;
            color: #f9f9f9;
        }
    }
}

.andArea {
    padding: 10px;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 10px;
}

.addConditionButton {
    padding: 5px 15px;
    border-radius: 22px;
    background: #3b82f6;
    color: #f9f9f9;
    margin-top: 10px;
}

.andAreaContainer {
    &:first-of-type {
        .andLabel {
            display: none;
        }
    }
    .andLabel {
        margin-left: 25px;
        display: block;
        border-left: 1px solid rgb(226, 226, 226);
        padding-left: 10px;
    }
}