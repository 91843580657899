/* colours */
$c-bg: #f9f9f9;
$c-text: #475569;
$c-text-light: #64748b;
$c-border: #f1f5f9;
$c-border-dark: #cbd5e1;
$c-primary: #73B0F4;
$c-dark: #1f2937;

// new colors
$blue-100: #E8F0F8;
$blue-200: #D3E5F7;
$blue-300: #C6D9ED;
$blue-400: #9EC7F1;
$blue-500: #63ADF6;
$blue-600: #0080FF;
$blue-700: #0066FF;
$blue-800: #0058FF;

$blue-light-100: #EAF1F7;
$blue-light-200: #DCEBF9;
$blue-light-300: #AFCFF1;
$blue-light-400: #93CCF3;
$blue-light-500: #6DC7F7;
$blue-light-600: #38B7FB;
$blue-light-700: #03A6FF;
$blue-light-800: #0999FF;

$dark-100: #C2C2C2;
$dark-200: #A0A0A0;
$dark-300: #5D5D5D;
$dark-400: #3A3A3A;
$dark-500: #2A2A2A;
$dark-600: #1A1A1A;

$light-100: #F9F9F9;
$light-200: #F2F2F2;
$light-300: #EBEBEB;
$light-400: #E5E5E5;
$light-500: #DEDEDE;
$light-600: #D7D7D7;

$success-100: #D3EFDC;
$success-200: #AEE6C1;
$success-300: #89DCA5;
$success-400: #62D187;
$success-500: #3AC569;
$success-600: #30A458;
$success-700: #278446;
$success-800: #1D6335;

$danger-100: #F5D3D4;
$danger-200: #F2AEB0;
$danger-300: #EF898C;
$danger-400: #EA6266;
$danger-500: #E53A40;
$danger-600: #BF3035;
$danger-700: #99272B;
$danger-800: #731D20;

$warning-100: #F3E8C4;
$warning-200: #F8E7B1;
$warning-300: #FDE69E;
$warning-400: #FBD355;
$warning-500: #F9C00C;
$warning-600: #E8B105;
$warning-700: #A68008;
$warning-800: #7D6006;

$primary: $blue-700;
$secondary: $blue-light-700;
$dark: $dark-500;
$light: $light-100;
$success: $success-500;
$danger: $danger-500;
$warning: $warning-500;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #D73A17;

$c-red: #d95565;
$c-green: #10b981;
$c-blue: #73B0F4;
$c-darkblue: #3A4488;
$c-orange: #F0AA61;
$c-tint: #FBFBFF;
$c-purple: #8929E0;
$c-darkpurple: #556CB6;
