.addSenderItemsContainer {
    display: flex;
    margin-top: 20px;
    margin-bottom: 40px;
}

.addSenderItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: 0.2s ease-in-out;
    padding: 20px 0;
    cursor: pointer;

    div {
        margin: auto;
    }

    p {
        margin: auto;
        font-weight: 600;
        text-align: center;
    }

    &:hover {
        background: #e5e5e5;
        border-radius: 10px;
    }
}

.workingHoursTable {
    width: 100%;
    height: 300px;
    // thead {
    //     font-weight: bold;
    // }
    td {
        padding: 10px;
    }
}

.workingHourDelay {
    display: flex;

    .workingHourDelayTime {
        margin: 0 10px;
        width: 100%;
    }
}

.csOAuthView {
    .optionSelectCard {
        h2 {
            color: #2A2A2A;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 10px;
        }

        & > div > div {
            display: flex;
            padding: 5px 0;
            svg {
                margin-right: 10px;
            }
        }
    }
    ol {
        li {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

.row-column-responsive {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
    @media (min-width: 992px) {
        flex-direction: row;
        margin-top: 1rem;
    }
}

.grid-layout {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 1rem;
    gap: 1rem;
    @media (min-width: 992px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}