.spamBody {
    margin-top: 16px;

    @media (min-width: 992px) {
        padding: 0 20px;
        margin-top: 0;
    }

    h2 {
        font-weight: 500;
        font-size: 16px;
    }

    p {
        font-weight: 400;
        font-size: 14px;
    }
}

.spamBoxes {
    display: flex;
}

.spamFlexBox {
    width: 100%;
    text-align: center;
    padding: 16px 0;
    background: #F2F2F2;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    cursor: default;

    .spamInfoTitle {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
    }
}

.spamCheckerBox {
    display: flex;
    cursor: default;
    padding: 11px;
    color: #3a3a3a;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    margin: 8px 0;
}

.attachmentContainer {
    padding: 22px;
    .attachmentItem {
        border: 1px solid #d7d7d7;
        background-color: #f2f2f2;
        color: #3a3a3a;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        margin: 10px 0;
        .attachmentItemContent {
            width: 88%;
            word-wrap: break-word;
        }
        button {
            width: 24px;
            height: 24px;
            padding: 3px;
            cursor: pointer;
            color: #E53A40;
            border-radius: 5px;
            border: 1px solid #EF898C;
            background: #F5D3D4;
            margin-left: auto;

            // &:hover {
            //     background-color: #EF4444;
            //     color: #F5D3D4;
            // }
        }
    }
    .addAttachmentItem {
        padding: 10px;
        width: 100%;
    }
}

.attachmentDropzone {
    padding: 0px;
    border-radius: 5px;
    text-align: center;
    position: relative;
    color: #a0a0a0;
}

.attachmentsFileDropper {
    position: absolute;
    border-radius: 5px;
    background: #f9f9f9;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.csGenerateButton {
    width: 144px;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    color: #03A6FF;
    border: 3px solid #03A6FF;
    border-radius: 8px;
    
    &.danger {
        color: #E53A40;
        border-color: #E53A40;
    }

    span {
        svg {
            path {
                fill: #03A6FF;
            }
        }
    }

    &:hover {
        color: #38B7FB;
        border: 3px solid #38B7FB;

        span path {
            fill: #38B7FB;
        }
    }

    &.danger:hover {
        color: #F2AEB0;
        border-color: #F2AEB0;
    }

    &:active {
        color: #0999FF;
        border: 3px solid #0999FF;

        span path {
            fill: #0999FF;
        }
    }
    &.danger:active {
        color: #BF3035;
        border-color: #BF3035;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.csSaveBtn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 13px 20px;
    background-color: #0066FF;
    color: #f9f9f9;
    border: 3px solid #99c2ff;
    border-radius: 8px;

    &:hover {
        background-color: #0080FF;
        border-color: #98ccf3;
    }

    &:active {
        background-color: #0058FF;
        border-color: #99bdfe;
    }
}

.tox-editor-header, .tox-toolbar-overlord, .tox-toolbar__primary, .tox-toolbar__group, .tox-tbtn--bespoke {
    background-color: #d7d7d7 !important;
    z-index: 1 !important;
}
.tox-sidebar-wrap, .tox-edit-area {
    background-color: #f9f9f9 !important;
    iframe {
        background-color: #f9f9f9 !important;
    }
}
.tox-statusbar .tox-statusbar__help-text {
    display: none;
}

@media (max-width: 1512px) {
    #personalizedImage {
        overflow: visible !important;
    }
}

@media (min-width: 1600px) {
    #personalizedImage {
        overflow: visible !important;
    }
}

#template-editor-container {
    padding-block: 16px;
    margin-left: 0;
    margin-right: 0;
    @media (min-width: 992px) {
        padding: 20px;
        margin-left: 20px;
        margin-right: 20px;
        border: 1px solid #D7D7D7;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
    }
}

#entire-editor-container {
    padding: 0 16px;
    @media (min-width: 992px) {
        padding: 0 40px;
    }
}

#template-name-input {
    min-width: 280px;
    @media (min-width: 992px) {
        width: 368px;
    }
}

#business-offer-container {
    flex-direction: column;
    align-items: start;
    @media (min-width: 992px) {
        flex-direction: row;
        align-items: center;
    }

    #business-offer-input {
        min-width: 280px;
        @media (min-width: 992px) {
            width: 507px;
        }
    }

    #language-input {
        margin-left: 0;
        margin-bottom: 0;
        @media (min-width: 992px) {
            margin-left: 1rem;
            margin-bottom: 1rem;
        }
    }

    #generate-with-chatgpt {
        margin-top: 16px;
        @media (min-width: 992px) {
            margin-top: 0;
            margin-left: 16px;
        }
    }
}

#subject-line-input {
    min-width: 280px;
    @media (min-width: 992px) {
        width: 671px;
    }
}

#improve-with-gpt-button {
    margin-left: 2px;
    margin-right: 0; 
    margin-top: 1rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (min-width: 992px) {
        margin-left: auto;
        margin-right: 16px; 
        justify-content: flex-end;
        flex-wrap: nowrap;
    }

    #sync-animation {
        margin-block: 16px;
        @media (min-width: 992px) {
            margin-block: 0;
        }
    }
}

#editor-top-toolbar {
    gap: 1rem;
    @media (min-width: 992px) {
        gap: 0.5rem;
    }
}

#discard-button {
    margin-right: 1rem;
    margin-left: 0;
    @media (min-width: 992px) {
        margin-left: auto;
    }
}

#save-button {
    margin-top: 8px;
    @media (min-width: 992px) {
        margin-top: 0;
    }
}