.csSettingsSidebar {
    display: none;
    @media (min-width: 992px) {
        display: block;
        width: 300px;
        height: 80vh;
        border-right: 1px solid #D7D7D7;
        padding-right: 30px;
        border-radius: 0;
    }

    .csSettingsSidebarItem {
        display: flex;
        padding: 10px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        margin: 8px 0;
        width: 276px;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);

        .csSidebarLabel {
            line-height: 17px;
            margin-left: 10px;
        }

        &:hover {
            background: #F2F2F2;
        }

        &.active {
            background: #F2F2F2;
        }
    }

}

.scroller {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.scroller::-webkit-scrollbar { 
    display: none !important;
}

.csSettingsContentArea {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    scroll-behavior: smooth;
    @media (min-width: 992px) {
        margin: 0 30px;
        height: 73vh;
    }

    .custom-profile-image-dashboard {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100px;
        border-radius: 50%;
        border: 1px solid #4a4a4a;
        overflow: hidden;

        &:hover {
            .custom-profile-image-dashboard-image-edit {
                display: grid;
            }
        }
        
        .custom-profile-image-dashboard-image {
            $size: 100px;
            width: clamp($size, $size, $size);
            height: clamp($size, $size, $size);
            border: none;
        }
        .custom-profile-image-dashboard-image-edit {
            width: 100px;
            height: 100px;
            position: absolute;
            margin: auto;
            display: none;
            place-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            cursor: pointer;
        }
    }

    .tox-statusbar {
        display: none !important;
    }

    .number-input {
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;
        padding: 6px;

        input {
            background-color: #F9F9F9;
        }
    }
    
    .number-input__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        outline: none;
    }
    
    // .number-input__btn svg {
    //     width: 16px;
    //     height: 16px;
    // }
    
    .number-input__field {
        // width: 80px;
        padding: 5px;
        text-align: center;
    }

    .csSettingsContentHeader {
        color: #3A3A3A;
        font-size: 24px;
        font-weight: 500;
    }

    .csSettingsContentSection {
        margin: 20px 0;
        margin-right: 10px;
        @media (min-width: 992px) {
            padding: 20px;
            border-radius: 5px;
            border: 1px solid rgb(232, 232, 232);
        }

        &.meetingScheduler {
            width: 560px;
            @media (min-width: 992px) {
                .meeting-slug-field {
                    font-size: 24px;
                }
            }

            #custom-domain-checkbox-container {
                flex-direction: column;
                @media (min-width: 992px) {
                    flex-direction: row;
                }

                #custom-domain-dropdown {
                    margin-top: 0.5rem;
                    @media (min-width: 992px) {
                        margin-left: auto;
                        margin-top: 0;
                    }
                }
            }
        }

        &.meeting-general-settings {
            @media (min-width: 992px) {
                padding-left: 5px;
            }

            .cs-Row {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                @media (min-width: 992px) {
                    flex-direction: row;
                    gap: 4rem;
                    padding: 0 20px;
                }

                #color-picker {
                    padding-left: 0.25rem;
                    @media (min-width: 992px) {
                        padding-left: 0;
                    }
                }
            }

            #meeting-advanced-settings {
                @media (min-width: 992px) {
                    padding-left: 20px;
                }

                .add-top-margin {
                    margin-top: 0.5rem;
                    @media (min-width: 992px) {
                        margin-top: 0;
                    }
                }

                #add-holiday-button {
                    margin-left: 4px;
                    @media (min-width: 992px) {
                        margin-left: -20px;
                    }
                }
            }
        }

        #attendees-list-settings {
            flex-direction: column;
            @media (min-width: 992px) {
                flex-direction: row;
            }

            #new-list {
                margin-left: 4px;
                @media (min-width: 992px) {
                    margin-left: auto;
                }
            }
        }

        #mobile-device-message {
            display: block;
            @media (min-width: 992px) {
                display: none;
            }
        }

        #booking-form-container {
            display: none;
            @media (min-width: 992px) {
                display: block;
            }
        }

        &.calendarConflict {
            width: 683px;
            #check-conflict-in-calendar {
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
                @media (min-width: 992px) {
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 10;
                }
            }
        }
        &.redirectUrl {
            width: 542px;
        }

        &.danger-zone-settings {
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #f1acaf;
            @media (min-width: 992px) {
                padding: 20px;
                margin-bottom: 100px;
            }

            #disconnect-calendar {
                flex-direction: column;
                gap: 1rem;
                @media (min-width: 992px) {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 0;
                }
            }
        }
    }
    #mobile-save-settings-button {
        display: block;
        margin-bottom: 1rem;
        border-top: 2px solid #d7d7d7;
        @media (min-width: 992px) {
            display: none;
        }
    }
}

.csCustomFieldsContainer {
    width: 1000px;
    margin: 0 auto; /* Center the container horizontally */

    .csCustomFieldsAddButton {
        display: flex;
        padding: 10px;
        background: #f4f4f4;
        border-radius: 10px;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s ease-in-out;

        &:hover {
            color: #f9f9f9;
            background: #007acc;
        }
    }
}

.csCustomFieldItemCol {
    width: 100%;
}

.csCustomFieldItemDrag {
    cursor: grab;
}

.csCustomFieldItemRemove {
    margin-left: 7px;
    cursor: pointer;
    &:hover {
        color: red;
    }
}

.csCustomFieldItemAdd {
    margin-left: 7px;
    cursor: pointer;
    &:hover {
        color: #3b82f6;
    }
}

.csCustomFieldButton {
    display: flex;
    padding: 13px;
    background: #f9f9f9;
    transition: 0.2s ease-in-out;
}

.csCIOptionsContainer {
    margin: 10px 40px;
}

.attachmentItem {
    border: 1px solid #d7d7d7;
    background-color: #f2f2f2;
    color: #3a3a3a;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    margin: 10px 0;
    .attachmentItemContent {
        width: 100%;
    }
    button {
        width: 24px;
        height: 24px;
        padding: 3px;
        cursor: pointer;
        color: #E53A40;
        border-radius: 5px;
        border: 1px solid #EF898C;
        background: #F5D3D4;
        margin-left: 5px;
    }
}