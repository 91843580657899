.viewCreditCard {
    background-color: #06f;
    color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid #63ADF6;

    .cardType {
        font-size: 16px;
        font-weight: 500;
    }

    .cardNumber {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 500;
    }

    .cardDetail {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        margin-top: 20px;

        .cardValidity {
            text-align: center;
        }
        .cardCvv {
            margin-left: 20px;
            text-align: center;
        }
    }
}