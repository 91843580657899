#folderButtons {
    padding: 8px !important;
    @media (min-width: 992px) {
        padding: 12px 48px !important;
    }
}

#sequence-menu {
    display: none;
    @media (min-width: 992px) {
        display: block;
    }
}

#create-sequence-button {
    padding-left: 30px !important;
    margin-bottom: 10px;
    @media (min-width: 992px) {
        padding-left: 15px !important;
    }
}

#filter-button {
    padding-left: 30px !important;
    margin-bottom: 10px;
    @media (min-width: 992px) {
        display: none;
    }
}

#show-in-mobile {
    display: flex;
    @media (min-width: 992px) {
      display: none;
    }
}
  
#hide-in-mobile {
    display: none;
    @media (min-width: 992px) {
        display: grid;
    }
}