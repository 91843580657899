.radial-progress-bar {
    position: relative;
    display: inline-block;
}

.progress-circle {
    width: 100%;
    height: 100%;
}

.progress-background {
    fill: none;
    stroke: #e6e6e6;
    stroke-width: 3.8;
}

.progress-value {
    fill: none;
    stroke: #4caf50;
    stroke-width: 3.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.5s ease;
}

.percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}