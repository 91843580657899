.sequenceStats {
    .statCard {
        padding: 10px 20px;
        background: #f9f9f9;
        border: 1px solid #d7d7d7;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        display: flex;
      
        div {
            text-align: center;
        
            &.statCount {
                font-size: 32px;
                font-weight: 500;
                display: flex;
                align-items: baseline;
                justify-content: center;

                .statPercent {
                        font-size: 14px;
                    }
            }
        }
    }
}

#stats-heading {
    flex-direction: column;

    #logs-buttons {
        margin-left: 15px;
        margin-top: 16px;
        @media (min-width: 992px) {
            margin-left: auto;
            margin-top: 0;
        }    
    }

    @media (min-width: 992px) {
        flex-direction: row;
    }
}