@import "../global/color.scss";

.SubNav {
    display: none;
    margin: -1.5625em -0.5em 1.5625em -0.5em;
    background-color: $light-200;
    border: 1px solid $light-600;
    border-radius: 5px;
    padding: 5px;
    
    @media (min-width: 992px) {
        display: flex;
    }

    .SubNavMenuItem {
        padding: 7px 14px;
        display: flex;
        cursor: pointer;
        color: rgb(100,100,100);
        transition: 0.2s ease-in-out;
        position: relative;
        border-bottom: 1px solid transparent;
        color: $dark-400;
        font-weight: 100;


        .SubNavItemTitle {
            margin-left: 10px;
            text-transform: capitalize;
            font-weight: 500;
        }

        &:hover {
            .SubNavItemTitle, .SubNavItemIcon {
                color: $blue-light-700;
            }
        }

        &.active {
            background-color: $light-100;
            border-radius: 5px;
            border: 1px solid $light-600;
            .SubNavItemTitle, .SubNavItemIcon {
                color: $blue-light-700;
            }
        }

    }
}