@import "../global/color.scss";

.csModalBackdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    background: rgba(0,0,0, 0.5);
    z-index: 5;
    color: $dark-400;

    .csModalContent {
        background: #f2f2f2;
        margin: auto;
        border-radius: 10px;
        padding: 10px;
        max-height: 100vh;
        overflow-y: auto;

        @media (min-width: 992px) {
            min-height: 300px;
            min-width: 800px;
        }

        .closeBtn {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            border: 3px solid currentColor;
            color: #E53A40;
            transition: 0.2s ease-in-out;
            display: grid;
            place-items: center;

            &:hover {
                color: #EA6266;
                cursor: pointer;
            }
        }
    }
    .csModalTitleContainer {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 1rem;
        // border-bottom: 1px solid #D7D7D7;

        .csModalTitle {
            h1 {
                color: #3A3A3A;
                font-family: Rubik;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
    .csModalBody {
        min-height: 200px;
        border-radius: 10px;
        overflow: hidden;
    }
}

.scroller {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.scroller::-webkit-scrollbar { 
    display: none !important;
}