.csVEDragItem {
    display: flex;
    padding: 11px;
    background: #f0f0f0;
    border-bottom: 1px solid rgb(225, 225, 225);
    cursor: default;

    &:active {
        cursor: grab;
    }

    svg {
        margin-top: 5px;
        margin-right: 5px;
    }

    &:first-of-type {
        border-radius: 10px 10px 0 0;
    }

    &:last-of-type {
        border-radius: 0 0 10px 10px;
        border-bottom: 0;
    }
}

.csCustomFieldsContainer {
    width: 100%;

    .csCustomFieldsAddButton {
        display: flex;
        padding: 10px;
        background: #f4f4f4;
        border-radius: 10px;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s ease-in-out;

        &:hover {
            color: #f9f9f9;
            background: #007acc;
        }
    }
}

.csCustomFieldItemCol {
    width: 100%;
}

.csCustomFieldItemDrag {
    cursor: grab;
    margin-right: 7px;
}

.csCustomFieldItemRemove {
    margin-left: 7px;
    cursor: pointer;

    &:hover {
        color: red;
    }
}

.csCustomFieldButton {
    display: flex;
    padding: 15px 16px 17px 15px;
    background: #f4f4f4;
    margin-top: 8px;
    border-radius: 6px;
    transition: 0.2s ease-in-out;
}

.csCIOptionsContainer {
    margin: 10px 40px;
}