@import "../global/color.scss";

.subsubnav {
    display: flex;
    background-color: $light-200;
    width: fit-content;
    border-radius: 5px;
    border: 1px solid $light-600;
    color: $dark-400;
    overflow: hidden;
    margin-top: -1rem;
    margin-left: -0.5rem;
    margin-bottom: 1rem;
    cursor: pointer;

    &-item {
        display: flex;
        gap: 0.5rem;
        font-weight: 500;
        padding: 0.65rem 1rem;

        &.active {
            color: $blue-light-700;
            background-color: $light-100;
            border-radius: 5px;
            outline: 1px solid $light-600;
        }
    }
}