#mobile-device-meet-message {
    display: flex;
    height: 100dvh;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    @media (min-width: 992px) {
        display: none;
    }
}

#large-screen-content {
    display: none;
    @media (min-width: 992px) {
        display: block;
    }
}

#mobile-meet-nav {
    @media (min-width: 992px) {
        display: none;
    }
}

.csExpander {
    width: 160px;
    background: #f9f9f9;
    cursor: pointer;
    border: 1px solid grey;
}

.csExpanderLabel {
    padding: 10px;
}

.csExpanderExpanded {
    background: rgb(222, 222, 222);
    padding: 10px;
}

.csJustifyContentCenter {
    text-align: justify;
    text-align-last: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

#codeContainer {
    display: block;
    background: #f5f5f5;
    border-radius: 10px;
    padding: 14px;
    border: 1px solid #eaeaea;
}