.csContainer {
    display: flex;
    touch-action: none;
    width: 100%;
    height: 100%;
    margin: 1rem auto;
}
  
.csDropzone {
    flex: 1;
    height: 100vh;
    border-radius: 1rem;
}

.csDropzone.left {
    margin-right: 10px;
}

.csGrid-item {
    width: 100%;
    height: 'max-content';
    padding: 0.5em;
    cursor: pointer;
}

.custom-drag-and-drop {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    /* Add additional styles as needed */

    @media (min-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px;
    }

    .item::-webkit-scrollbar {
        display: none;
    }

    .item {
        display: flex;
        align-items: stretch;
        // align-items: center;
        // justify-content: center;
        // height: max-content;
        // background-color: #f2f2f2;
        // border: 1px solid #ccc;
        cursor: move;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        
        /* Add additional styles as needed */
        @media (min-width: 1200px) {
            margin: 5px;
        }
    }

    .csCardTitle {
        color: #3A3A3A;
        font-size: 24px;
        font-weight: 500;
        display: flex;

        svg {
            margin-top: 5px;
            margin-right: 10px;
        }
    }

    .statBox {
        width: 210px;
        height: 117px;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
        text-align: center;
        padding-top: 20px;

        h1 {
            color: #343434;
            font-size: 32px;
            font-weight: 500;
        }

        p {
            color: #414141;
            font-size: 15px;
            font-weight: 500;
        }
    }

    .csGrid-item-content {
        width: 100%;
        height: 100%;
    }
}
  
.cockpitOption {
    margin-bottom: 10px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #D7D7D7;
    background: #F9F9F9;
    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    &.active {
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F2F2F2;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
    }
}

#customizedCockpitRow {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    @media (min-width: 1200px) {
      grid-template-columns: 3fr 1fr;
    }
}

#mobile-device-cockpit-message {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    @media (min-width: 992px) {
        display: none;
    }
}

#large-screen-content {
    display: none;
    @media (min-width: 992px) {
        display: block;
    }
}