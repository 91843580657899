.w-domains, .w-links {
    margin-top: 32px;
}

.row-column-responsive {
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
    }
}

.margin-left-responsive {
    margin-left: 0;
    max-width: max-content;
    @media (min-width: 992px) {
        margin-left: auto;
    }
}

@media (min-width: 992px) {
    .w-links {
        margin-top: 0;
    } 
}

@media (min-width: 1200px) {
    .w-domains {
        margin-top: 0;
    }
}