@import '../../components/global/color.scss';

.statCard {
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #d7d7d7;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;

  div {
    text-align: center;

    &.statCount {
      font-size: 32px;
      font-weight: 500;
    }
  }
}

.dayCardtrue {
  padding: 5px;
  height: auto;
  margin-left: 10px;
  background: #F3E8C4;
  border: 2px solid #FBD355;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;

  div {
    text-align: center;

    &.statCount {
      font-size: 28px;
      font-weight: 300;
    }
  }
}
.dayCardfalse {
  padding: 5px;
  height: auto;
  margin-left: 10px;
  background: #D3EFDC;
  border: 2px solid #89DCA5;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;

  div {
    text-align: center;

    &.statCount {
      font-size: 28px;
      font-weight: 300;
    }
  }
}



.email-sender-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
  max-height: 55px;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .group {
    display: flex;
    align-items: center;
    gap: 1rem;
    max-height: 46px;
    padding: 3px;
    margin-top: 0.5rem;

    @media (min-width: 992px) {
      margin-top: 0;
      overflow: hidden;
    }

    #gradient-thingy-wrapper {
      $size: 92px;
      min-width: $size;
      min-height: $size;
      border-radius: 50%;
      position: relative;
      top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: 1px solid $light-600;
      outline-offset: -1px;
      isolation: isolate;

      &::before {
        $difference: 15px;
        content: '';
        position: absolute;
        z-index: 2;
        height: calc($size - $difference);
        width: calc($size - $difference);
        background-color: $light-100;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        outline: 1px solid $light-600;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        height: 2px;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        outline: 1px solid $light-600;
      }

      .plan-usage {
        z-index: 100;
        top: -1rem;
        position: relative;
        font-weight: 500;
        font-size: 24px;
      }
    }
  }
}

#email-sender-page-title {
  margin-top: 4rem;
  @media (min-width: 992px) {
    margin-top: 0;
  }
}

#email-sender-filter {
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
}

#email-sender-lists {
  margin-top: 28px;
  background-color: #f9f9f9;
  @media (min-width: 992px) {
    margin-top: 0;
  }
  @media only screen and (orientation: landscape) {
    margin-top: 4px; 
}
}

#mobile-email-sender-filter {
  display: block;
  @media (min-width: 992px) {
    display: none;
  }
}

.custom-buttons {
  padding: 8px !important;
  @media (min-width: 992px) {
      padding: 12px 48px !important;
  }
}

#warmup-sender-header {
  flex-direction: column;
  gap: 8px;
  @media (min-width: 992px) {
    flex-direction: row;
    gap: 0;
  }

  #back-to-sender {
    display: none;
    @media (min-width: 992px) {
      display: block;
    }
  }

  #warmup-sender-title {
    margin-top: 0;
    margin-left: 0;
    @media (min-width: 992px) {
      margin-top: 0.75rem;
      margin-left: 1rem;
    }
  }

  #rem-days {
    margin-left: 0;
    @media (min-width: 992px) {
      margin-left: 10px;
    }
  }

  #warmup-settings-container {
    display: flex;
    align-items: center;
    margin-left: 0;
    @media (min-width: 992px) {
      margin-left: auto;
    }
  }
}

#recent-warmup-logs {
  margin-top: 16px;
  @media (min-width: 992px) {
    margin-top: 0;
  }
}

.row-column-responsive {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
  @media (min-width: 992px) {
      flex-direction: row;
      margin-top: 0;
  }
}

#show-in-mobile {
  display: flex;
  @media (min-width: 992px) {
    display: none;
  }
}

#hide-in-mobile {
  display: none;
  @media (min-width: 992px) {
    display: grid;
  }
}