@import "../../components/global/color.scss";

.report-modal-content-item-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $light-600;
  border-radius: 5px;
  padding: 1rem;
  min-width: 200px;
  width: calc(50% - 0.5rem);
  box-shadow: -2px 2px 4px rgba(#000, 0.05);

  .key-value {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .external-link {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      cursor: pointer;

      &:hover {
        svg {
          display: initial;
        }
      }

      .key {
        font-weight: 500;
      }

      svg {
        fill: $blue-500;
        height: 20px;
        width: 20px;
        display: none;
      }
    }

    .value {
      font-size: 1.5rem;
      font-weight: 500;

      .percent{
        font-size: 14px;
        margin-left: 0 !important;
      }
    }
  }
}

#buttons-container, #header-container {
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 992px) {
    flex-direction: row;
    gap: 0;
  }
}