.csDynamicAttributeAddButton {
    display: flex;
    padding: 10px;
    background: #f4f4f4;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        color: #f9f9f9;
        background: #007acc;
    }
}

.custom-buttons {
    padding: 8px !important;
    @media (min-width: 992px) {
        padding: 12px 48px !important;
    }
}

#add-task-template {
    margin-top: 0;
    @media (min-width: 992px) {
        margin: 40px;
        padding: 20px;
    }
}