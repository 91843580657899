@import "../../components/global/color.scss";

#singleListFilter {
    display: none;
    @media (min-width: 992px) {
        display: block;
    }
}

#singleListHeader {
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
        align-items: center;
    }

    #lastEdit {
        margin-left: 0;
        @media (min-width: 992px) {
            margin-left: 15px;
        }
    }

    #download-add-buttons {
        margin-left: 0;
        margin-top: 8px;
        @media (min-width: 992px) {
            margin-left: auto;
            margin-top: 0;
        }
    }

    #singleListMobileFilter {
        display: block;
        @media (min-width: 992px) {
            display: none;
        }
    }
}

.listNameInput {
    margin-left: 0;
    input.p-3 {
        padding: 3px !important;
        margin-top: -4px !important;
    }
    @media (min-width: 992px) {
        margin-left: 11px;
    }
}

.edit-contact-backdrop {
    min-height: 100vh;
    min-width: 100vw;
    z-index: 90;
    position: fixed;
    inset: 0;

    .edit-contact {
        $padding: 2.5rem;

        color: $dark-400;
        min-height: 100vh;    
        width: 100%;
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        padding: 1.5rem;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);

        @media (min-width: 992px) {
            width: 435px;
            padding: $padding;               
        }

        h2 {
            font-size: 24px;
            font-weight: 500;
        }

        .close-button {
            color: $danger-500;
            position: absolute;
            right: $padding;
            top: $padding;
            border: 3px solid $danger-500;
            border-radius: 5px;
            width: 35px;
            height: 35px;
            display: grid;
            place-items: center;
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-block: 24px;
            max-height: calc(100vh - 12rem);
            overflow-y: auto;

            .input {
                display: flex;
                flex-direction: column;
                gap: 5px;
                
                label {
                    font-size: 16px;
                    font-weight: 500;
                }
                
                input {
                    height: 40px;
                    width: 100%;
                    padding: 10px 15px;
                    border-radius: 5px;
                    border: 1px solid $light-600;
                    background: $light-100;
                    font-size: 16px;

                    @media (min-width: 992px) {
                        width: 350px;
                    }

                    &:focus {
                        border: 1px solid $dark-200;
                    }
                }
            }
        }
    }
}

#show-in-mobile {
    display: flex;
    @media (min-width: 992px) {
      display: none;
    }
}
  
#hide-in-mobile {
    display: none;
    @media (min-width: 992px) {
        display: grid;
    }
}