.bg-blue {
  background-color: #EAF1F7;
  border: 1px solid #93CCF3;
}
.text-blue {
  color: #03a6ff;
}
.bg-green {
  background-color: #D3EFDC;
  border: 1px solid #89DCA5;
}
.text-green {
  color: #3ac569;
}
.bg-orange {
  background-color: #f3e8c4;
  border: 1px solid #FBD355;
}
.text-orange {
  color: #f9c00c;
}
.bg-red {
  background-color: #F5D3D4;
  border: 1px solid #EF898C;
}
.text-red {
  color: #e53a40;
}
