.react-flow {
    background-color: #f2f2f2;
}

.conditionalFooterTrueFalse {
    display: flex;

    .conditionalFooterItem {
        width: 100%;
        text-align: center;
    }
}

.csAndPill {
    display: block;
    padding: 1px 10px;
    width: fit-content;
    background: green;
    color: #f9f9f9;
    border-radius: 14px;
}

.conditionView {
    padding: 2px 7px;
    background: #48a7ec;
    color: #f9f9f9;
    border-radius: 10px;
    margin-bottom: 2px;
}

.csFlowchartBlock {
    .csNodeActionHeader {
        position: absolute;
        right: -10px;
        top: -10px;
    
        .actionBtn {
            width: 24px;
            height: 24px;
            padding: 3px;
            cursor: pointer;
            display: none;
        
            &.edit {
                color: #F9C00C;
                border-radius: 5px;
                border: 1px solid #FBD355;
                background: #F3E8C4;
            }
        
            &.delete {
                color: #E53A40;
                border-radius: 5px;
                border: 1px solid #EF898C;
                background: #F5D3D4;
                margin-left: 5px;
            }
        }
    }

    .blockTitle {
        color: #3A3A3A;
        font-size: 16px;
        font-family: Rubik;
        font-weight: 500;
    }

    .blockDescription { 
        color: #3A3A3A;
        font-size: 16px;
    }

    .textPrimary { color: #03A6FF; }

    .textWarning { color: #F9C00C; }

    .textPink { color: #ED317F; }

    .textPurple { color: #8929E0; }

    .csSequenceIcon {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid;
    
        &.primary {
            border-color: #93CCF3;
            background: #EAF1F7;
            color: #03A6FF;
        }
    
        &.warning {
            border-color: #FBD355;
            background: #F3E8C4;
            color: #F9C00C;
        }
    
        &.pink {
            border-color: #F484B2;
            background: #FBD6E5;
            color: #ED317F;
        }
    
        &.purple {
            border-color: #9F52E6;
            background: #E3CCF8;
            color: #8929E0;
        }
    }

    .csSequencePills {
        border-radius: 5px;
        border: 1px solid;
        font-size: 14px;
        font-weight: 500;
        padding: 3px 0;

        &.primary {
            border-color: #93CCF3;
            background: #EAF1F7;
            color: #03A6FF;
        }

        &.pink {
            border-color: #F484B2;
            background: #FBD6E5;
            color: #ED317F;
        }
    }

    &:hover {
        .actionBtn {
            display: block;
        }
    }
}

.react-flow__edge-path, .react-flow__connection-path {
	stroke: #E5E5E5 !important;
	stroke-width: 4px !important;
}

.react-flow__handle {
	border-radius: 8px !important;
	border: 2px solid rgba(215, 215, 215, 0.40) !important;
	background: #D7D7D7 !important;
}

.edgebutton-foreignobject {
    display: none !important;
}

.react-flow__edge-path:hover + .edgebutton-foreignobject,
.edgebutton-foreignobject:hover {
    display: block !important;
}