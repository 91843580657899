.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
}

.switch input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3AC569;
    outline: 2px solid #b0e8c3;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: #f9f9f9;
    border-radius: 50%;
    transition: transform 0.4s;
}

input[type='checkbox']:hover + .slider {
    background-color: #62D187;
    outline: 2px solid #c1eccf;
}

input[type='checkbox']:checked + .slider {
    background-color: #3AC569;
    outline: 2px solid #b0e8c3;
}

input[type='checkbox']:checked:hover + .slider {
    background-color: #62D187;
    outline: 2px solid #c1eccf;
}

input[type='checkbox']:checked + .slider:before {
    transform: translateX(20px);
}