.persona {
    button.dropBtn, button.createBtn {
        padding: 5px 10px;
        font-size: 14px;
        width: 100%;
        // float: right;
        border: none;
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    button.dropBtn {
        background-color: #3498DB;
        color: #f9f9f9;
        &:hover, &:focus {
            background-color: #2980B9;
        }
    }
    button.createBtn {
        margin-top: 5px;
        border: 1px solid #a2a2a2;
        &:hover, &:focus {
            background-color: #3498DB;
            color: #f9f9f9;
        }
    }
    .dropdownContent {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #e5e5e5;
        overflow: hidden;
        input {
            margin: 0 auto;
            margin-top: 5px !important;
            width: 90%;
            border: 1px solid #e5e5e5;
            padding: 5px 10px;
            border-radius: 5px;
            &:focus { outline: none; }
        }
        .personaList {
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            max-height: 100px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 3px;
            }
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                outline: 1px solid slategrey;
            }
            a {
                margin-left: 5px;
                color: black;
                padding: 8px 12px;
                text-decoration: none;
                &:hover { background-color: #ddd; }
            }
        }
    }
    .hide { display: none }
}