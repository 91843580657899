@import "../../components/global/color.scss";

#phone-page-container {
    display: flex;
    @media (min-width: 750px) {
        justify-content: center;
        align-items: center;
    }
}

#phone-page-heading {
    margin-top: 180px;
    @media only screen and (max-width: 991px) and (orientation: landscape) {
        margin-top: 60px;
    }
}

#phone-page-input {
    height: 200px;
    width: 350px;
    @media (min-width: 992px) {
        width: 500px;
    }
}

.auth {
    &-signup {
        $padding: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        padding: $padding;
        color: $dark-400;
        background-color: $light-100;
        max-width: 1920px;
        margin: auto;

        @media (max-width: 975px) {
            flex-direction: column;
            gap: 2rem;
        }

        .main, .details {
            flex: 1;
            width: clamp(300px, 100%, 960px);
        }

        .main {
            display: flex;
            flex-direction: column;
            gap: 50px;

            .header, .signup-form, .redirect-to-signin, .footer {
                max-width: 500px;
                margin-inline: auto;
            }

            .header {
                $max-width: 275px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;

                .logo, h1 {
                    max-width: $max-width;
                }

                .logo {
                    img {
                        max-width: calc($max-width - 2rem);
                    }

                }

                h1 {
                    font-size: 16px;
                }
            }

            .signup-form {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .oauth {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    justify-content: center;

                    .button {
                        padding: 0 12px;
                        border: 1px solid #747775;
                        border-radius: 5px;
                        font-weight: 500;
                        font-family: 'Roboto', arial, sans-serif;
                        font-size: 14px;
                        color: #1f1f1f;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        height: 40px;
                        background-color: white;
                    }
                }

                .separator {
                    position: relative;
                    text-align: center;
                    isolation: isolate;
                    z-index: 2;

                    &::before {
                        content: "";
                        height: 1px;
                        width: 100%;
                        background-color: $light-600;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        z-index: 1;
                    }

                    span {
                        position: relative;
                        z-index: 2;
                        background-color: $light-100;
                        padding-inline: 2rem;
                        color: $dark-200;
                    }
                }

                .form {
                    padding: 30px;
                    border-radius: 5px;
                    border: 1px solid $light-600;
                    background: $light-100;
                    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);

                    button {
                        width: max-content !important;
                        margin-left: 50%;
                        margin-top: 1rem;
                        transform: translateX(-50%);
                    }

                }

                .redirect-to-signin {
                    a {
                        color: #0066FF;
                        font-size: 16px;
                    }
                }
            }

            .footer {
                width: fit-content;
            }
        }

        .details {
            position: relative;
            border-radius: 5px;
            border: 2px solid #AFCFF1;
            box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);

            @media (max-width: 975px) {
                min-height: 1000px;
            }

            .background {
                position: absolute;
                z-index: 3;
                inset: 0;
                overflow: hidden;
                
                .hover-thingy {
                    position: absolute;
                    z-index: 2;

                    &.left {
                        $size: 81%;
                        width: $size;
                        height: $size;
                        transform: rotate(-169.668deg);
                        border-radius: 739.106px;
                        background: radial-gradient(95.12% 95.12% at 74.48% 41.67%, #06F 0%, rgba(217, 217, 217, 0.00) 100%);
                        left: 0;
                        transform: translate(-50%, -10%);
                    }

                    &.top-right {
                        $size: 70%;
                        width: $size;
                        height: $size;
                        border-radius: 639px;
                        background: radial-gradient(95.12% 95.12% at 74.48% 41.67%, #06F 0%, rgba(217, 217, 217, 0.00) 100%);
                        right: 0%;
                        top: 0;
                        transform: translate(16%, -25%);
                    }

                    &.right {
                        $size: 75%;
                        width: $size;
                        height: $size;
                        flex-shrink: 0;

                        border-radius: 683px;
                        background: radial-gradient(95.12% 95.12% at 74.48% 41.67%, #DA00FF 0%, rgba(217, 217, 217, 0.00) 100%);
                        right: 0;
                        bottom: 0;
                        transform: translate(20%, 20%);
                    }

                    &.bottom {
                        $size: 29%;
                        width: $size;
                        height: $size;
                        aspect-ratio: 1 / 1;
                        flex-shrink: 0;
                        border-radius: 50%;
                        background: radial-gradient(95.12% 95.12% at 74.48% 41.67%, #E53A40 0%, rgba(217, 217, 217, 0.00) 100%);
                        transform: translate(70%, 45%);
                        bottom: 0;
                    }
                }
            }


            .content {
                position: absolute;
                z-index: 4;
                backdrop-filter: blur(100px);
                background-color: rgba(255, 255, 255, 0.5);
                inset: 0;
                padding-block: 100px;
                padding-inline: 100px;
                display: flex;
                flex-direction: column;
                gap: 45px;

                @media (max-width: 600px) {
                    padding-inline: 10px;
                }
                @media (max-width: 700px) {
                    padding-inline: 50px;
                }
                
                .header {
                    h2 {
                        font-size: 32px;
                        font-weight: 600;
                        max-width: 550px;
                        line-height: normal;
                        margin-bottom: 20px;
                    }
                }

                .image {
                    height: 250px;
                    background-color: white;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-size: 32px;
                    font-weight: 600;
                }

                .images {
                    display: flex;
                    flex-direction: column;
                    gap: 100px;


                    div {
                        display: flex;
                        justify-content: space-between;
                        
                        img {
                            width: 150px;
                            min-width: 75px;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
    &-signin {
        $padding: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        padding: $padding;
        color: $dark-400;
        background-color: $light-100;
        max-width: 1920px;
        margin: auto;

        @media (max-width: 975px) {
            flex-direction: column;
            gap: 2rem;
        }

        .main, .details {
            flex: 1;
            width: clamp(300px, 100%, 960px);
           
        }

        .main {
            display: flex;
            flex-direction: column;
            gap: 50px;

            .header, .signin-form, .redirect-to-signin, .footer {
                max-width: 500px;
                margin-inline: auto;
                width: 100%;
            }

            .header {
                $max-width: 230px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;

                .logo, h1 {
                    max-width: $max-width;
                }

                .logo {
                    img {
                        max-width: calc($max-width - 2rem);
                    }

                }

                h1 {
                    font-size: 16px;
                }

                p {
                    font-size: 24px;
                    font-weight: 600;
                    margin-top: 2rem;
                }
            }

            .signin-form {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .oauth {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    justify-content: center;

                    .button {
                        padding: 0 12px;
                        border: 1px solid #747775;
                        border-radius: 5px;
                        font-weight: 500;
                        font-family: 'Roboto', arial, sans-serif;
                        font-size: 14px;
                        color: #1f1f1f;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        height: 40px;
                        background-color: white;
                    }
                }

                .separator {
                    position: relative;
                    text-align: center;
                    isolation: isolate;
                    z-index: 2;

                    &::before {
                        content: "";
                        height: 1px;
                        width: 100%;
                        background-color: $light-600;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        z-index: 1;
                    }

                    span {
                        position: relative;
                        z-index: 2;
                        background-color: $light-100;
                        padding-inline: 2rem;
                        color: $dark-200;
                    }
                }

                .form {
                    padding: 30px;
                    border-radius: 5px;
                    border: 1px solid $light-600;
                    background: $light-100;
                    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);

                    button {
                        width: max-content !important;
                        margin-left: 50%;
                        margin-top: 1rem;
                        transform: translateX(-50%);
                    }

                }

                .redirect-to-signup {
                    a {
                        color: #0066FF;
                        font-size: 16px;
                    }
                }
            }

            .footer {
                width: fit-content;
            }
        }

        .details {
            position: relative;
            border-radius: 5px;
            border: 2px solid #AFCFF1;
            box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
            min-height: 900px;

            .background {
                position: absolute;
                z-index: 3;
                inset: 0;
                overflow: hidden;
                
                .hover-thingy {
                    position: absolute;
                    z-index: 2;

                    &.left {
                        $size: 81%;
                        width: $size;
                        height: $size;
                        transform: rotate(-169.668deg);
                        border-radius: 739.106px;
                        background: radial-gradient(95.12% 95.12% at 74.48% 41.67%, #06F 0%, rgba(217, 217, 217, 0.00) 100%);
                        left: 0;
                        transform: translate(-50%, -10%);
                    }

                    &.top-right {
                        $size: 70%;
                        width: $size;
                        height: $size;
                        border-radius: 639px;
                        background: radial-gradient(95.12% 95.12% at 74.48% 41.67%, #06F 0%, rgba(217, 217, 217, 0.00) 100%);
                        right: 0%;
                        top: 0;
                        transform: translate(16%, -25%);
                    }

                    &.right {
                        $size: 75%;
                        width: $size;
                        height: $size;
                        flex-shrink: 0;

                        border-radius: 683px;
                        background: radial-gradient(95.12% 95.12% at 74.48% 41.67%, #DA00FF 0%, rgba(217, 217, 217, 0.00) 100%);
                        right: 0;
                        bottom: 0;
                        transform: translate(20%, 20%);
                    }

                    &.bottom {
                        $size: 29%;
                        width: $size;
                        height: $size;
                        aspect-ratio: 1 / 1;
                        flex-shrink: 0;
                        border-radius: 50%;
                        background: radial-gradient(95.12% 95.12% at 74.48% 41.67%, #E53A40 0%, rgba(217, 217, 217, 0.00) 100%);
                        transform: translate(70%, 45%);
                        bottom: 0;
                    }
                }
            }


            .content {
                position: absolute;
                z-index: 4;
                backdrop-filter: blur(100px);
                inset: 0;
                padding-block: 100px;
                padding-inline: 100px;
                padding-right: 30px;
                display: flex;
                flex-direction: column;
                gap: 45px;
                background-color: rgba(255, 255, 255, 0.5);


                @media (max-width: 600px) {
                    padding-inline: 10px;
                }
                @media (max-width: 700px) {
                    padding-inline: 10px;
                }
                
                .header {
                    h2 {
                        font-size: 32px;
                        font-weight: 600;
                        max-width: 550px;
                        line-height: normal;
                        margin-bottom: 20px;
                    }
                }

                .images {
                    $z-index: 10;
                    position: relative;
                    height: 450px;

                    img {
                        position: absolute;
                        width: 65%;
                    }

                    .one {
                        z-index: $z-index;
                    }
                    .two {
                        z-index: calc($z-index - 1);
                        top: 200px;
                        left: 50px;
                        top: 0;
                        left: 0;
                        transform: translate(8%, 70%);
                    }
                    .three {
                        z-index: calc($z-index - 2);
                        right: 0;
                        top: 0;
                        transform: translate(0, 8%);
                    }
                }
            }
        }
    }
}

.gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: min-content;
  }
  
  .gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }
  
  .gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  
  .gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity .218s;
    transition: opacity .218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }