@import "../../components/global/color.scss";

.contactActivitySection {

    h2 {
        font-size: 24px;
        font-weight: 500;
    }
    
    .editContactSection {
    
        .inputs {
            // display: flex;
            // flex-direction: column;
            // gap: 1rem;
            margin-block: 24px;
            max-height: calc(100vh - 12rem);
            overflow-y: auto;

            .input {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-bottom: 10px;
                
                label {
                    font-size: 16px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 0.25rem;
                }
                
                input {
                    height: 40px;
                    // width: 300px;
                    padding: 10px 15px;
                    border-radius: 5px;
                    border: 1px solid $light-600;
                    background: $light-100;
                    font-size: 16px;

                    &:focus {
                        border: 1px solid $dark-200;
                    }
                }
            }
        }

        .csCustomFieldsAddButton {
            display: flex;
            padding: 10px;
            background: #f4f4f4;
            border-radius: 10px;
            font-weight: bold;
            cursor: pointer;
            transition: 0.2s ease-in-out;
    
            &:hover {
                color: #f9f9f9;
                background: #007acc;
            }
        }
    }
}

.external-link {
    display: inline-flex;
    gap: 0.25rem;
    align-items: center;

    &:hover {
        svg {
            visibility: visible;
        }
    }
    svg {
        height: 15px;
        width: 15px;
        visibility: hidden;
        display: inline;
    }
}

#lead-dates {
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
    }

    #last-updated {
        @media (min-width: 992px) {
            margin-left: 0.5rem;
            border-left: 2px solid #3a3a3a;
            padding-left: 10px;
        }
    }

    #is-in-list, #last-updated {
        margin-top: 0.25rem;
        @media (min-width: 992px) {
            margin-top: 0;
        }
    }
}

#single-activities {
    padding: 1rem;
    @media (min-width: 992px) {
        padding: 0px 2rem 0px 2rem;
    }
}