.templatesContaier {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 6px;
}

#row-column-responsive {
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
    }
}

.list-details-container {
    display: grid;
    grid-template-columns: 250px 1fr;

    .list-details, .list-details-values {
        div {
            height: 60px;
            display: flex;
            align-items: center;
            gap: 15px;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 16px;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }
        }
    }

    .list-details  {
        div {
            display: flex;

            span {
                color: var(--accent-color);
                display: inline-block;
                border: 1px solid var(--border-color);
                background-color: var(--background-color);
                padding: 5px;
                border-radius: 5px;
            }
        }
    }

    .list-details-values {
        div {
            font-weight: 400;
        }
    }
}

.csSequenceCardIcon {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid;

    &.primary {
        border-color: #93CCF3;
        background: #EAF1F7;
        color: #03A6FF;
    }

    &.warning {
        border-color: #FBD355;
        background: #F3E8C4;
        color: #F9C00C;
    }

    &.pink {
        border-color: #F484B2;
        background: #FBD6E5;
        color: #ED317F;
    }

    &.purple {
        border-color: #9F52E6;
        background: #E3CCF8;
        color: #8929E0;
    }

    &.orange {
        border-color: #fda761;
        background: #fed3b0;
        color: #fc913a;
    }
}

#templates-filter {
    display: none;
    @media (min-width: 992px) {
        display: block;   
    }
}

#create-new-template-button-container {
    justify-content: flex-start;
    margin-left: 0;
    margin-top: 8px;
    @media (min-width: 992px) {
        justify-content: flex-end;
        margin-left: auto !important;
        margin-right: 0 !important;
        padding: 0 !important;
        margin-top: 8px;
        width: 100%;
        max-width: 100%;
    }

    #create-new-template-button {
        margin-left: 0;
        @media (min-width: 992px) {
            margin-left: auto !important;
        }
    }
}

#mobile-template-filter {
    display: block;
    margin-left: 15px;
    @media (min-width: 992px) {
        display: none;
    }
}

.custom-buttons {
    padding: 8px !important;
    @media (min-width: 992px) {
        padding: 12px 48px !important;
    }
}