@import "../global/color.scss";

.csExpander {
    background: $light-100;
    cursor: pointer;
    border: 1px solid $light-600 !important;
    box-shadow: -2px 2px 4px rgba(#000, 0.05);
    margin: 10px 0;
    border-radius: 5px;
    width: 100%;
    color: $dark-400;
}
.csExpanderLabel {
    display: flex;
    gap: 0.75rem;
    padding: 10px;
    font-weight: 500;

    .arrow {
        margin-left: auto;
        stroke-width: 3;
    }
}
.csExpanderExpanded {
    background: $light-100 !important;
    border-radius: 0 0 20px 20px;
    padding: 5px 10px 10px 10px;
}