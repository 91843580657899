.rdrDefinedRangesWrapper {
    display: none;
    @media (min-width: 992px) {
        display: flex;
    }
}

#date-picker-dropdown {
    left: 0;
    @media (min-width: 992px) {
        left: 323px;
    }
}

#chart-filter {
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
    }
}