#conditions-header {
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
    }

    #new-condition-button {
        margin-left: 0;
        width: 150px;
        @media (min-width: 992px) {
            margin-left: auto;
            width: unset;
        }
    }
}