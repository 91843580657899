@import "../global/color.scss";

.customButton {
  display: flex;
  gap: 10px;
  border-radius: 5px;
  font-weight: 500;
  justify-content: center;
  align-items: center;

  &.layout-reverse {
      flex-flow: row-reverse;
  }

  &.primary {
      outline: 3px solid rgba($blue-700, 0.4);
      background-color: $blue-700;
      color: $light-100;
    
      svg {
        color: currentColor;
      }
    
      &:hover {
        background-color: $blue-600;
      }
      &:active {
        background-color: $blue-800;
      }
      &.disabled {
        background-color: $blue-300;
      }
    
      &.red {
        outline-color: rgba($danger-500, 0.4);
        background-color: $danger-500;
    
        &:hover {
          background-color: $danger-400 !important;
        }
        &:active {
          background-color: $danger-600 !important;
        }
        &.disabled {
          background-color: $danger-200 !important;
        }
      }
    
      &.green {
        outline-color: rgba($success-600, 0.4);
        background-color: $success-600;
    
        &:hover {
          background-color: $success-500 !important;
        }
        &:active {
          background-color: $success-700 !important;
        }
        &.disabled {
          background-color: $success-200 !important;
        }
      }
    
      &.orange {
        outline-color: rgba($warning-600, 0.4);
        background-color: $warning-600;
    
        &:hover {
          background-color: $warning-500 !important;
        }
        &:active {
          background-color: $warning-700 !important;
        }
        &.disabled {
          background-color: $warning-200 !important;
        }
      }
      &.grey {
        outline-color: rgba($dark-200, 0.4);
        background-color: $dark-200;
    
        &:hover {
          background-color: $dark-100 !important;
        }
        &:active {
          background-color: $dark-300 !important;
        }
        &.disabled {
          background-color: $light-500 !important;
        }
      }
    }
    
  &.secondary {
    outline: 3px solid $blue-light-700;
    color: $blue-light-700;
    background: none !important;
  
    svg {
      color: currentColor;
    }
  
    &:hover {
      outline-color: $blue-light-600;
      color: $blue-light-600;
    }
    &:active {
      outline-color: $blue-light-800;
      color: $blue-light-800;
    }
    &.disabled {
      outline-color: $blue-light-300;
      color: $blue-light-300;
    }
  
    &.red {
      outline-color: $danger-500;
      color: $danger-500;
  
      &:hover {
        outline-color: $danger-400;
        color: $danger-400;
      }
      &:active {
        outline-color: $danger-600;
        color: $danger-600;
      }
      &.disabled {
        outline-color: $danger-200;
        color: $danger-200;
      }
    }
  
    &.green {
      outline-color: $success-500;
      color: $success-500;
      background: none !important;
  
      &:hover {
        outline-color: $success-400;
        color: $success-400;
      }
      &:active {
        outline-color: $success-600;
        color: $success-600;
      }
      &.disabled {
        outline-color: $success-200;
        color: $success-200;
      }
    }
  
    &.orange {
      outline-color: $warning-500;
      color: $warning-500;
  
      &:hover {
        outline-color: $warning-400;
        color: $warning-400;
      }
      &:active {
        outline-color: $warning-600;
        color: $warning-600;
      }
      &.disabled {
        outline-color: $warning-200;
        color: $warning-200;
      }
    }

    &.grey {
      outline-color: $dark-200;
      color: $dark-200;
  
      &:hover {
        outline-color: $dark-100;
        color: $dark-100;
      }
      &:active {
        outline-color: $dark-300;
        color: $dark-300;
      }
      &.disabled {
        outline-color: $light-500;
        color: $light-500;
      }
    }
  }
}