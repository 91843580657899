@import './global/color';
@import './global/scale';
@import './global/utilities';
@import './global/animation';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

* {
  scrollbar-width: auto !important;
  scrollbar-color: #d7d7d7 #e5e5e5 !important;
}

*::-webkit-scrollbar {
  width: 10px !important;
}

*::-webkit-scrollbar-track {
  border-radius: 50px !important;
  background: #f9f9f9 !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #d7d7d7 !important;
  border: 3px solid #f9f9f9 !important;
  border-radius: 22px !important;
}

body {
	overflow-x: hidden !important;
	background-color: $light-100;
}

.rounded {
    border-radius: $radius !important;
    box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.05);
}

.csContainer {
    display: flex;
	.csCol100 {
        width: 100%;
    }
    .csCol70 {
        width: 70%;
    }
    .csCol30 {
        width: 30%;
    }
}

.csRow {
    display: flex;
    margin-bottom: 10px;

    .csCol50 {
        width: 100%;

        &:last-child {
            margin-left: 10px;
        }

        &:first-child {
            margin-right: 10px;
        }
    }
}

.csFormLoader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
}

.react-flow__renderer {
    z-index: 0 !important;
}

#circularG{
	position:relative;
	width:58px;
	height:58px;
	margin: auto;
}

.circularG{
	position:absolute;
	background-color:rgb(26,135,255);
	width:14px;
	height:14px;
	border-radius:9px;
		-o-border-radius:9px;
		-ms-border-radius:9px;
		-webkit-border-radius:9px;
		-moz-border-radius:9px;
	animation-name:bounce_circularG;
		-o-animation-name:bounce_circularG;
		-ms-animation-name:bounce_circularG;
		-webkit-animation-name:bounce_circularG;
		-moz-animation-name:bounce_circularG;
	animation-duration:1.1s;
		-o-animation-duration:1.1s;
		-ms-animation-duration:1.1s;
		-webkit-animation-duration:1.1s;
		-moz-animation-duration:1.1s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
}

#circularG_1{
	left:0;
	top:23px;
	animation-delay:0.41s;
		-o-animation-delay:0.41s;
		-ms-animation-delay:0.41s;
		-webkit-animation-delay:0.41s;
		-moz-animation-delay:0.41s;
}

#circularG_2{
	left:6px;
	top:6px;
	animation-delay:0.55s;
		-o-animation-delay:0.55s;
		-ms-animation-delay:0.55s;
		-webkit-animation-delay:0.55s;
		-moz-animation-delay:0.55s;
}

#circularG_3{
	top:0;
	left:23px;
	animation-delay:0.69s;
		-o-animation-delay:0.69s;
		-ms-animation-delay:0.69s;
		-webkit-animation-delay:0.69s;
		-moz-animation-delay:0.69s;
}

#circularG_4{
	right:6px;
	top:6px;
	animation-delay:0.83s;
		-o-animation-delay:0.83s;
		-ms-animation-delay:0.83s;
		-webkit-animation-delay:0.83s;
		-moz-animation-delay:0.83s;
}

#circularG_5{
	right:0;
	top:23px;
	animation-delay:0.97s;
		-o-animation-delay:0.97s;
		-ms-animation-delay:0.97s;
		-webkit-animation-delay:0.97s;
		-moz-animation-delay:0.97s;
}

#circularG_6{
	right:6px;
	bottom:6px;
	animation-delay:1.1s;
		-o-animation-delay:1.1s;
		-ms-animation-delay:1.1s;
		-webkit-animation-delay:1.1s;
		-moz-animation-delay:1.1s;
}

#circularG_7{
	left:23px;
	bottom:0;
	animation-delay:1.24s;
		-o-animation-delay:1.24s;
		-ms-animation-delay:1.24s;
		-webkit-animation-delay:1.24s;
		-moz-animation-delay:1.24s;
}

#circularG_8{
	left:6px;
	bottom:6px;
	animation-delay:1.38s;
		-o-animation-delay:1.38s;
		-ms-animation-delay:1.38s;
		-webkit-animation-delay:1.38s;
		-moz-animation-delay:1.38s;
}



@keyframes bounce_circularG{
	0%{
		transform:scale(1);
	}

	100%{
		transform:scale(.3);
	}
}

@-o-keyframes bounce_circularG{
	0%{
		-o-transform:scale(1);
	}

	100%{
		-o-transform:scale(.3);
	}
}

@-ms-keyframes bounce_circularG{
	0%{
		-ms-transform:scale(1);
	}

	100%{
		-ms-transform:scale(.3);
	}
}

@-webkit-keyframes bounce_circularG{
	0%{
		-webkit-transform:scale(1);
	}

	100%{
		-webkit-transform:scale(.3);
	}
}

@-moz-keyframes bounce_circularG{
	0%{
		-moz-transform:scale(1);
	}

	100%{
		-moz-transform:scale(.3);
	}
}

.text-modal-header {
	font-size: 22px;
	font-weight: bold;
	padding-left: 15px;
	padding-bottom: 5px;
	line-height: 2rem;
}
.text-modal-description {
	font-size: 16px;
    line-height: 20px;
    padding-left: 15px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    opacity: 0.8;
}

.bg-grey-500 {
	background-color: #c1c4d6 !important;
}

.bg-red-500 {
	background-color: $danger !important;
}

.bg-emerald-500 {
	background-color: $success !important;
}

// primary color classes
.bg-primary {
	background-color: $primary !important;
}
.bg-blue-100 {
	background-color: $blue-100 !important;
}
.bg-blue-200 {
	background-color: $blue-200 !important;
}
.bg-blue-300 {
	background-color: $blue-300 !important;
}
.bg-blue-400 {
	background-color: $blue-400 !important;
}
.bg-blue-500 {
	background-color: $blue-500 !important;
}
.bg-blue-600 {
	background-color: $blue-600 !important;
}
.bg-blue-700 {
	background-color: $blue-700 !important;
}
.bg-blue-800 {
	background-color: $blue-800 !important;
}
.hover\:bg-blue-100:hover {
	background-color: $blue-100 !important;
}
.hover\:bg-blue-200:hover {
	background-color: $blue-200 !important;
}
.hover\:bg-blue-300:hover {
	background-color: $blue-300 !important;
}
.hover\:bg-blue-400:hover {
	background-color: $blue-400 !important;
}
.hover\:bg-blue-500:hover {
	background-color: $blue-500 !important;
}
.hover\:bg-blue-600:hover {
	background-color: $blue-600 !important;
}
.hover\:bg-blue-700:hover {
	background-color: $blue-700 !important;
}
.hover\:bg-blue-800:hover {
	background-color: $blue-800 !important;
}
.border-blue-800 {
	outline: 3px solid rgba($blue-800, 0.4) !important;
}
.active\:bg-blue-100:active {
	background-color: $blue-100 !important;
}
.active\:bg-blue-200:active {
	background-color: $blue-200 !important;
}
.active\:bg-blue-300:active {
	background-color: $blue-300 !important;
}
.active\:bg-blue-400:active {
	background-color: $blue-400 !important;
}
.active\:bg-blue-500:active {
	background-color: $blue-500 !important;
}
.active\:bg-blue-600:active {
	background-color: $blue-600 !important;
}
.active\:bg-blue-700:active {
	background-color: $blue-700 !important;
}
.active\:bg-blue-800:active {
	background-color: $blue-800 !important;
}
.text-blue-100 {
	color: $blue-100 !important;
}
.text-blue-200 {
	color: $blue-200 !important;
}
.text-blue-300 {
	color: $blue-300 !important;
}
.text-blue-400 {
	color: $blue-400 !important;
}
.text-blue-500 {
	color: $blue-500 !important;
}
.text-blue-600 {
	color: $blue-600 !important;
}
.text-blue-700 {
	color: $blue-700 !important;
}
.text-blue-800 {
	color: $blue-800 !important;
}


// secondary color classes
.bg-secondary {
	background-color: $secondary !important;
}
.bg-blue-light-100 {
	background-color: $blue-light-100 !important;
}
.bg-blue-light-200 {
	background-color: $blue-light-200 !important;
}
.bg-blue-light-300 {
	background-color: $blue-light-300 !important;
}
.bg-blue-light-400 {
	background-color: $blue-light-400 !important;
}
.bg-blue-light-500 {
	background-color: $blue-light-500 !important;
}
.bg-blue-light-600 {
	background-color: $blue-light-600 !important;
}
.bg-blue-light-700 {
	background-color: $blue-light-700 !important;
}
.bg-blue-light-800 {
	background-color: $blue-light-800 !important;
}
.hover\:bg-blue-light-100:hover {
	background-color: $blue-light-100 !important;
}
.hover\:bg-blue-light-200:hover {
	background-color: $blue-light-200 !important;
}
.hover\:bg-blue-light-300:hover {
	background-color: $blue-light-300 !important;
}
.hover\:bg-blue-light-400:hover {
	background-color: $blue-light-400 !important;
}
.hover\:bg-blue-light-500:hover {
	background-color: $blue-light-500 !important;
}
.hover\:bg-blue-light-600:hover {
	background-color: $blue-light-600 !important;
}
.hover\:bg-blue-light-700:hover {
	background-color: $blue-light-700 !important;
}
.hover\:bg-blue-light-800:hover {
	background-color: $blue-light-800 !important;
}
.border-blue-light-800 {
	outline: 3px solid rgba($blue-light-800, 0.4) !important;
}
.active\:bg-blue-light-100:active {
	background-color: $blue-light-100 !important;
}
.active\:bg-blue-light-200:active {
	background-color: $blue-light-200 !important;
}
.active\:bg-blue-light-300:active {
	background-color: $blue-light-300 !important;
}
.active\:bg-blue-light-400:active {
	background-color: $blue-light-400 !important;
}
.active\:bg-blue-light-500:active {
	background-color: $blue-light-500 !important;
}
.active\:bg-blue-light-600:active {
	background-color: $blue-light-600 !important;
}
.active\:bg-blue-light-700:active {
	background-color: $blue-light-700 !important;
}
.active\:bg-blue-light-800:active {
	background-color: $blue-light-800 !important;
}
.text-blue-light-100 {
	color: $blue-light-100 !important;
}
.text-blue-light-200 {
	color: $blue-light-200 !important;
}
.text-blue-light-300 {
	color: $blue-light-300 !important;
}
.text-blue-light-400 {
	color: $blue-light-400 !important;
}
.text-blue-light-500 {
	color: $blue-light-500 !important;
}
.text-blue-light-600 {
	color: $blue-light-600 !important;
}
.text-blue-light-700 {
	color: $blue-light-700 !important;
}
.text-blue-light-800 {
	color: $blue-light-800 !important;
}

// secondary color classes
.bg-danger {
	background-color: $danger !important;
}
.bg-danger-100 {
	background-color: $danger-100 !important;
}
.bg-danger-200 {
	background-color: $danger-200 !important;
}
.bg-danger-300 {
	background-color: $danger-300 !important;
}
.bg-danger-400 {
	background-color: $danger-400 !important;
}
.bg-danger-500 {
	background-color: $danger-500 !important;
}
.bg-danger-600 {
	background-color: $danger-600 !important;
}
.bg-danger-700 {
	background-color: $danger-700 !important;
}
.bg-danger-800 {
	background-color: $danger-800 !important;
}
.hover\:bg-danger-100:hover {
	background-color: $danger-100 !important;
}
.hover\:bg-danger-200:hover {
	background-color: $danger-200 !important;
}
.hover\:bg-danger-300:hover {
	background-color: $danger-300 !important;
}
.hover\:bg-danger-400:hover {
	background-color: $danger-400 !important;
}
.hover\:bg-danger-500:hover {
	background-color: $danger-500 !important;
}
.hover\:bg-danger-600:hover {
	background-color: $danger-600 !important;
}
.hover\:bg-danger-700:hover {
	background-color: $danger-700 !important;
}
.hover\:bg-danger-800:hover {
	background-color: $danger-800 !important;
}
.border-danger-800 {
	outline: 3px solid rgba($danger-800, 0.4) !important;
}
.active\:bg-danger-100:active {
	background-color: $danger-100 !important;
}
.active\:bg-danger-200:active {
	background-color: $danger-200 !important;
}
.active\:bg-danger-300:active {
	background-color: $danger-300 !important;
}
.active\:bg-danger-400:active {
	background-color: $danger-400 !important;
}
.active\:bg-danger-500:active {
	background-color: $danger-500 !important;
}
.active\:bg-danger-600:active {
	background-color: $danger-600 !important;
}
.active\:bg-danger-700:active {
	background-color: $danger-700 !important;
}
.active\:bg-danger-800:active {
	background-color: $danger-800 !important;
}

.bg-none {
	background: none !important;
}

.bg-yellow-500 {
	background-color: $warning !important;
}

.text-brand-500 {
	color: #474d66 !important;
}

.text-blue-500 {
	color: $primary !important;
}

.fc-h-event {
	background-color: transparent !important;
	border: none !important;
}
.fc-daygrid-event-harness {
	overflow: hidden;
	padding: 0 3px;
	border-radius: 2px;
}
.fc-daygrid-event-harness:hover {
	// display: inline-flex !important;
	opacity: 1 !important;
	background: #f9f9f9;
	z-index: 100000;
	transition: 0.1s ease-in-out;
}

.fc .fc-daygrid-day.fc-day-today {
	background-color: transparent !important;
}

.csOpacity-7 {
	opacity: 0.7;
}

input[type="text"] {
	background-color: #f9f9f9;
}
input[type="checkbox"] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 25px;
	height: 25px;
	min-width: 25px;
	min-height: 25px;
	border-radius: 5px;
	border: 1px solid $light-600;
	background: none;
	background-size: 70%;
  	background-position: center center;
	outline: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

input[type="checkbox"]:hover {
	border-color: $dark-200;
}

input[type="checkbox"]:checked {
	// border: 2px solid rgba($success-200, 0.4) !important;
	border: 1.5px solid rgba($success-200, 0.4) !important;
	background-color: $success-500;
	background-repeat: no-repeat;
	background-size: 0.8em;
	background-position: center center;
	background-image: url('./icons/ico-check-white.svg');
}

input[type="checkbox"]:checked:hover {
	background-color: $success-400;
	border-color: rgba($success-400, 0.4) !important;
}

input[type="checkbox"]:disabled {
	background-color: $light-400;
	border: none;
}

input[type="checkbox"]:checked:disabled {
	border: none;
	background-color: $success-500;
	opacity: 0.4;
}

input[type="checkbox"]:active {
	border-color: rgba($success-600, 0.4);
	background-repeat: no-repeat;
	background-size: 1em;
	background-position: center center;
	background-image: url('./icons/ico-check-white.svg');
}

input[type="checkbox"]:indeterminate {
	border: 1px solid #a2dab5 !important;
	background-color: #3AC468;
}

input[type="checkbox"]:indeterminate:hover {
	background-color: #62d187;
	border: 2px solid #b2dfc1 !important;
}

input[type="checkbox"]:indeterminate::before {
	content: "–";
	font-size: 32px;
	font-weight: bold;
	line-height: 20px;
	padding-left: 1.7px;
	color: #f9f9f9;
}

input[type="checkbox"]:indeterminate:hover::before {
	content: "–";
	font-size: 32px;
	font-weight: bold;
	line-height: 20px;
	padding-left: 1px;
	color: #f9f9f9;
}

.radio {
	display: flex;

	input[type="radio"] {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 24px;
		height: 24px;
		border: 1px solid #d7d7d7;
		border-radius: 50%;
		margin-right: 5px;
		outline: none;
		cursor: pointer;
		position: relative;

		&:hover { border: 1px solid #a0a0a0; }

		&:checked {
			background-color: #3AC569;
  			border-color: #a2dab5;

			&:hover {
				background-color: #62D187;
  				border-color: #b2dec0;
			}

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 13px;
				height: 13px;
				background-color: #f9f9f9;
				border-radius: 50%;
			}
		}
	}
}

.csCustomeLink {
	display: flex;
	text-decoration: none !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #3a3a3a !important;

	.csLinkIcon { display: none; }
	.csLinkEmpty {
		width: 21.8px;
	}

	&:hover {
		transition: all 0.2s ease-in-out;
		color: #03A6FF !important;

		.csLinkEmpty {
			display: none;
		}
		.csLinkIcon {
			display: block;
			margin: 2px 0 0 5px;
		}
	}
}

.csLink {
	transition: all 0.2s ease-in-out;
	color: #03A6FF !important;
	cursor: pointer;
	text-decoration: none !important;
}

.csBadge {
	display: inline-block;
	border-radius: 5px;
	padding: 1px 11px;

	&.primary {
		background: #DCEBF9;
		border: 2px solid #93CCF3;
	}

	&.primaryOutline {
		border: 2px solid #03A6FF;
	}

	&.success {
		background: #D3EFDC;
		border: 2px solid #89DCA5;
	}

	&.successOutline {
		border: 2px solid #89DCA5;
	}

	&.warning {
		background: #F3E8C4;
		border: 2px solid #FBD355;
	}

	&.warningOutline {
		border: 2px solid #FBD355;
	}

	&.info {
		background: #EAF1F7;
		border: 2px solid #AFCFF1;
	}

	&.infoOutline {
		border: 2px solid #AFCFF1;
	}

	&.danger {
		background: #F5D3D4;
		border: 2px solid #EF898C;
	}

	&.dangerOutline {
		border: 2px solid #EF898C;
	}

	&.gray {
		background: #c2c2c2;
		border: 2px solid #a0a0a0;
	}
}

.text-danger {
	color: #EF898C;
}

.text-success {
	color: #93CCF3;
}

.csIconFill {
	color: #f9f9f9;
	width: 25px;
	height: 25px;
	padding: 2.5px;
	background-color: #03A6FF;
	border: 2px solid #97d8fb;
	border-radius: 5px;

	&:hover {
		background-color: #38b7fb;
		border: 2px solid #ADDEFA;
	}
}

.csCenter {
	display: flex;
	justify-content: center;
}

.tableTitle {
	font-weight: 500;
	font-size: 24px;
}

.csSecondaryButton {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    color: #03A6FF !important;
    border: 3px solid #03A6FF;
    border-radius: 8px;
    
    &.danger {
        color: #E53A40;
        border-color: #E53A40;
    }

    &:hover {
        color: #38B7FB;
        border: 3px solid #38B7FB;

        span path {
            fill: #38B7FB;
        }
    }

    &.danger:hover {
        color: #F2AEB0;
        border-color: #F2AEB0;
    }

    &:active {
        color: #0999FF;
        border: 3px solid #0999FF;

        span path {
            fill: #0999FF;
        }
    }

    &.danger:active {
        color: #BF3035;
        border-color: #BF3035;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

	i,svg {
		margin-left: 5px;
	}
}

div.select-wrapper {
	box-shadow: none !important;
	& > select {
		box-shadow: none !important;
	}
}

div.csCard {
	border-radius: 5px;
	border: 1px solid #D7D7D7;
	background: #F9F9F9;
	box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.05);
	padding: 25px;

	&.hover:hover {
		background-color: #f2f2f2;
	}

	.primaryTitle {
		h1 {
			line-height: 40px;
			font-size: 40px;
			font-weight: 600;
		}
	}

	.secondaryTitle {
		h1 {
			font-size: 24px;
			font-weight: 600;
		}
	}
}

.csBorderRounded {
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, 0.20);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.csProgressBar {
	border: 1px solid #D7D7D7; 
	background-color: #F2F2F2; 
	border-radius: 2px;
	width: '100%';
	height: 8px;
	overflow: hidden;

	div {
		height: 7px;
		border-radius: 2px; 
	}

	&.success > div{
		background-color: #3AC569; 
		// border: 1px solid rgba(58, 197, 105, 0.40); 
	}
}

.focus-none:focus {
	border: none !important;
	outline: none !important;
}

// Remove novu branding
.nc-footer { display: none !important; }

.nc-notifications-list-item-unread {
	svg rect {
		fill: #03A6FF !important;
	}

	&::before {
		background: #03A6FF !important;
	}
}

.mantine-Badge-root.nc-unseen-badge {
	background: #03A6FF !important;
}

.mantine-Switch-track {
	background: #3AC468 !important;
}

// TinyMCE
.tox-tinymce,
.tox-editor-container,
.tox-editor-header {
	background-color: #f9f9f9 !important;

	.tox-toolbar-overlord,
	.tox-toolbar__primary,
	.tox-toolbar__overflow,
	.tox-toolbar__group {
		background-color: #f9f9f9 !important;

		button {
			background-color: #f9f9f9 !important;
		}
	}
}

.change_log {
    position: relative;
    margin-top: 15px;
    margin-right: 3px;
}
#HW_badge_cont {
    position: absolute;
    top: -10px;
    right: -10px;
}

.sync {
    animation: rotation infinite 3s linear;
}

.rotate {
    animation: rotation infinite 3s linear;
}

@keyframes rotation{
    from{
        transform:rotate(0deg);
    }
  
    to{
        transform:rotate(360deg);
    }
}