@import '../global/color.scss';

.searchPeople {
    border: 1px solid $light-600;
    border-radius: 5px;
    display: flex;
    padding: 8px;
    input {
        width: 100%;
        margin-left: 10px;
        &:focus { outline: none; }
    }

    svg {
        color: $dark-100;
    }
}