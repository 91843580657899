#edit-sequence-header, #edit-sequence-header-buttons, #schedule-header, .row-col-responsive {
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
    }
}

#edit-sequence-header-buttons {
    margin-top: 1rem;
    flex-direction: column;
    gap: 1rem;
    @media (min-width: 992px) {
        margin-left: auto;
        margin-top: 0;
        flex-direction: row;
        gap: 0;
        align-items: center;
    }
}

#grid-container {
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr,
    }
}

.dialogNodeItem {
    display: flex;
    padding: 20px;
    margin: 10px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #D7D7D7;
    background: #f9f9f9;

    &:hover {
        background: rgba(220, 220, 220, 0.5);
    }
    .csSequenceIcon {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid;
    
        &.primary {
            border-color: #93CCF3;
            background: #EAF1F7;
            color: #03A6FF;
        }
    
        &.warning {
            border-color: #FBD355;
            background: #F3E8C4;
            color: #F9C00C;
        }
    
        &.pink {
            border-color: #F484B2;
            background: #FBD6E5;
            color: #ED317F;
        }
    
        &.purple {
            border-color: #9F52E6;
            background: #E3CCF8;
            color: #8929E0;
        }
    }
}

.edgebutton-foreignobject div {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
}

.sequenceSettingsDialog {

    .showSummary, .showEdit {
        max-height: 70vh;
        overflow-y: auto;
    }

    h2 {
        color: #3A3A3A;
        font-size: 20px;
        font-weight: 500;
        display: flex;

        span {
            font-size: 16px;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #93CCF3;
            background: #EAF1F7;
        }

        svg {
            margin-top: 5px;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    label {
        color: #3A3A3A;
        font-size: 16px;
        font-weight: 500;
    }

    a {
        color: #06F;
        font-size: 14px;
        font-weight: 400;
    }

    .dialogTitle {
        display: flex;
        color: #3A3A3A;
        font-size: 20px;
        font-weight: 500;
    }

    .senderView {
        width: 390px;
        margin-top: 5px;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;

        div {
            padding: 7px 10px;

            &:nth-child(even) {
                background-color: #f2f2f2;
            }
        }
    }

    .trackingBox {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        border: 1px solid #D7D7D7;
        background: #F9F9F9;
        text-align: center;
        padding: 15px;
        
        div {
            color: #3A3A3A;
            font-size: 16px;
            font-weight: 500;
        }

        svg {
            margin: auto;
        }
    }
}

.custom-datetime-picker {
    /* Custom styles for the wrapper container */
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #D7D7D7;
    background: #F9F9F9;
    display: flex;

    &.only-date {
        .react-datetime-picker__clear-button {
            margin-left: 90px;
        }
    }

    .react-datetime-picker {
        /* Custom styles for the DateTimePicker component */
        font-family: Rubik;

        .react-datetime-picker__wrapper {
            // padding: 5px;
            // border-radius: 5px;
            border: none;
            background: transparent;
        }

        /* Example custom styles for input element */
        input {
            /* Custom input styles */
            // border: 1px solid #03A6FF;
        }

        /* Example custom styles for calendar */
        .react-datetime-picker__calendar .react-calendar {
            /* Custom styles for the calendar component */
            border-radius: 5px;
            border: 1px solid #D7D7D7;
            background: #F9F9F9;
            margin-top: 10px;
            margin-left: -32px;
        }

        .react-calendar__month-view__weekdays {
            color: #3A3A3A;

            abbr {
                text-decoration: none;
            }
        }

        /* Example custom styles for calendar days */
        .react-datetime-picker__calendar .react-calendar__tile {
            /* Custom styles for calendar days */

            color: #3A3A3A;

            &.react-calendar__month-view__days__day--neighboringMonth {
                color: #d7d7d7;
            }

            &.react-calendar__tile--active {
                border-radius: 5px;
                background: #03A6FF;
                color: #f2f2f2;
            }
        }

        /* Example custom styles for calendar navigation buttons */
        .react-datetime-picker__calendar .react-calendar__navigation {
            /* Custom styles for calendar navigation buttons */
            border-bottom: 1px solid #D7D7D7;

            .react-calendar__navigation__label__labelText--from {
                color: #3A3A3A;
                font-weight: 600;
            }
        }
    }
}