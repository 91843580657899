.notificationMenu {
    background: #f9f9f9;
    box-shadow: 0 0 10px -7px;
    border: 1px solid #e5e5e5;
    border-radius: 7px;
    padding: 10px;
    margin-top: 5px;
    position: absolute;
    width: 300px;
    height: 360px;
    margin-left: -260px;
    .notificationMenuHeader {
        display: flex;
        button {
            margin-left: auto;
            background-color: #e5e5e5;
            padding: 4px 10px;
            border-radius: 5px;
            transition: 0.2s ease-in-out;
            &:hover {
                background: rgb(119, 175, 232);
                color: #f9f9f9;
            }
            &:active {
                background: rgb(89, 155, 212);
            }
        }
        .nMhText {
            line-height: 31px;
        }
    }
    .notificationMenuBody {
        max-height: 300px;
        overflow-y: scroll;
    }
    .notificationMenuItem {
        display: flex;
        margin: 6px 0;
        padding: 10px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        .nmiLeft {
            width: 100%;
        }
        p {
            font-weight: 300;
        }
        .nmiRight {
            margin: auto;
            text-align: right;
            .markReadButton {
                color: #f9f9f9;
                padding: 2px;
                padding: 6px;
                border-radius: 10px;
                transition: 0.2s ease-in-out;
                border: 1px solid transparent;
                &:hover {
                    border: 1px solid #e5e5e5;
                }
                span {
                    background: rgb(119, 175, 232);
                    border-radius: 10px;
                    height: 8px;
                    width: 8px;
                    display: block;
                }
            };
        }
        &:hover {
            background-color: #f5f5f5;
            border-radius: 7px;
        }
        &.unread {
            background-color: azure;
        }
    }
}