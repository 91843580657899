@import "../global/color.scss";

.hoverMenu {
    display: flex;

    .hoverMenuItem {
        display: flex;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        position: relative;
        color: $dark-400;

        .hoverMenuItemTitle {
            margin-left: 10px;
        }

        &:hover {
            .hoverMenuItemTitle {
                color: $blue-light-700;
            }

            .hoverMenuItemIcon {
                color: $blue-light-700;
            }
        }

        &.active {
            background: $light-200;
            border-radius: 5px 5px 0px 0px;
            border: 1px solid $light-600;
            border-bottom: none;

            &::before {
                content: '';
                width: 100%;
                height: 1px;
                position: absolute;
                background-color: $light-200;
                bottom: -1px;
            }


            // .hoverMenuItemTitle {
            //     color: #007acc;
            // }

            // .hoverMenuItemIcon {
            //     color: #007acc;
            // }
        }
    }
}

.hmiDropdownMenu {
    background-color: $light-100;
    padding: 10px 15px;
    border-radius: 5px;

    margin-top: 58px;
    margin-left: -100px;
    position: absolute;
    min-width: 600px;
    border: 1px solid $light-600;
    box-shadow: -2px 2px 4px rgba(#000, 0.05);
    z-index: 1;

    .hmiDropdownMenuBody {
        overflow-y: auto;
        display: flex;
        flex-flow: row wrap;
    }

    .dropdownMenuItem {
        display: flex;
        padding: 10px;
        cursor: pointer;
        transition: 0.2s ease-in-out;

        &:hover {
            background: rgb(219 234 254);
            border-radius: 7px;
        }
    }
}

.hoverMenuInnerItemContainer {
    padding: 6px;
    flex-basis: 50%;
}

.hoverMenuInnerItem {
    display: flex;
    align-items: start;
    gap: 12px;
    padding: 15px;
    transition: 0.2s ease-in-out;
    border-radius: 5px;
    outline: none;

    .hoverMenuInnerIcon {
        padding: 12px;
        outline: 1px solid var(--accent-normal);
        color: var(--accent-normal);
        border-radius: 5px;
        background-color: var(--accent-hover);
    }

    .hoverMenuInnerBody {
        h2 {
            color: $dark-400;
            font-size: 16px;
        }

        span {
            color: $dark-400;
            font-weight: 400;
            font-size: 12px;
            line-height: 5px;
        }
    }

    .arrow {
        margin-left: auto;
        align-self: center;
        visibility: hidden;
    }

    &:hover {
        background-color: $light-200;
        outline: 1px solid $light-600;
        transition: none;

        .arrow {
            visibility: visible;
        }
    }
}