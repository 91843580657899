#logs-report-filter-download-buttons {
    display: flex;
    justify-content: space-between;
    @media (min-width: 992px) {
        justify-content: end;
    }
}

#toggle-filter-button {
    display: flex;
    @media (min-width: 992px) {
        display: none;
    }
}

#logs-report-filter {
    display: none;
    @media (min-width: 992px) {
        display: block;
    }
}

#download-reports {
    float: left;
    margin-top: 8px;
    @media (min-width: 992px) {
        float: right;
        margin-top: 0;
    }
}

#mobile-logs-report-filter {
    display: block;
    @media (min-width: 992px) {
        display: none;
    }
}