@import "../global/color.scss";

.csCustomSelect {
    background: #f9f9f9;
    cursor: pointer;
    border: 1px solid $light-600;
    border-radius: 5px;
    width: 100%;
    transition: 0.2s ease-in-out;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.02);
    font-weight: 500;
    color: $dark-400;
}

.csCustomSelectLabel {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 0.75rem;

    // span.selected {
    //     color: $blue-light-700;
    // }

    .arrow {
        margin-left: auto;
    }
}

.csCustomSelectOption {
    .csCustomSelectedOption {
        width: 100%;
        padding: 8px 10px;
        border-radius: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        &:hover {
            color: $blue-light-700;
            background: $blue-light-100;
        }
    }

    .csCustomOptionEdit {
        padding: 8px;
        border-radius: 5px;
        height: 100%;

        &.edit:hover {
            color: $warning-500;
        }
        &.trash:hover {
            color: $danger-500;
        }
    }
}

.csCustomSelectExpanded {
    padding: 5px;
}

.csCustomSelectActions {
    display: flex;
    gap: 0.5rem;

    .csCustomSelectBottomAction {
        padding: 10px;
        width: 100%;
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        border-radius: 7px;
        svg {
            margin-right: 5px;
        }

        &:hover {
            color: $blue-light-700;
            background: $blue-light-100;
        }
    }
}