@import "../../components/global/color.scss";

.emailApprove {

    #outbox-filter {
        @media (min-width: 992px) {
            padding: 0 10px;
            border-right: 1px solid #D7D7D7;
            padding-right: 40px;
        }
    }

    .contactContainer {
        background: #f9f9f9;
        margin-top: 20px;
        overflow-y: auto;
        border: 1px solid #D7D7D7;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
    }

    .taskItem {
        cursor: pointer;
        padding: 15px 20px;
        display: flex;

        &:nth-child(2n) {
            background-color: #f2f2f2 !important;
        }

        &.active{
            background: #DCEBF9 !important;
            border-radius: 4px 4px 0px 0px;
            transition: all .2s ease-in-out
        }
        &:hover {
            background: #DCEBF9 !important;
            border-radius: 4px 4px 0px 0px;
            transition: all .2s ease-in-out
        }
    }
}

#outbox-review-email {
    padding-left: 30px;
    @media (min-width: 992px) {
        padding-left: 40px;
    }
}

#review-email-basic-details {
    margin-top: 1rem;
    margin-left: -12px;
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
        margin-top: 0;
    }

    .to-from-div {
        display: flex;
        flex-direction: column;
        @media (min-width: 992px) {
            flex-direction: row;
            gap: 4px;
        }
    }

    .email-link {
        @media (min-width: 992px) {
            margin-left: 2px;
        }
    }

    #from-email {
        margin-top: 4px;
        @media (min-width: 992px) {
            margin-left: 0;
        }
    }
}

#improve-with-blink-gpt {
    right: 100%;
    @media (min-width: 992px) {
        right: 0;
    }
}

#subject-preview {
    margin-top: 16px;
    @media (min-width: 992px) {
        margin-top: 0;
    }
}

.emailView {
    a {
        color: #0000ee;
    }
}

.view-task {
    @media (min-width: 992px) {
        padding: 20px 30px;
        border: 1px solid #D7D7D7;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        margin-top: 13px;
    }
}

.view-task-links {

    .external-link {
        display: flex;
        gap: 0.5rem;

        .csLink {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &:hover .icon {
                visibility: visible;
            }

            .icon {
                visibility: hidden;
            }
        }
    }

    .email-task-info {
        margin-top: 1rem;
        
        p {
            color: $dark-300;
            font-weight: 500;
            
            a {
                font-weight: 400;
                color: $blue-700;
            }
        }
    }
}