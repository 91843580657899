#mobile-nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: fixed;
    top: 0;
    width: 250px;
    height: 100dvh;
    z-index: 999999;
    padding: 1.625em;
    background-color: #f9f9f9;
    min-height: 100dvh;
    overflow-y: auto;

    a.active {
        color: #03a6ff;
    }

    @media (min-width: 992px) {
        display: none;
    }
}

.scroller {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}
  
.scroller::-webkit-scrollbar { 
    display: none !important;
}